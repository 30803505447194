import Joi from "joi";

const schema = Joi.object({
  section_name_1: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case "string.empty":
            err.message = "スタッフタブ名を入力してください。";
            break;
          default:
            break;
        }
      });
      return errors;
    }),
}).options({ abortEarly: false, allowUnknown: true });

export default schema;
