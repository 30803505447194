import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "./index.scss";
import { nanoid } from "nanoid";

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // chosenDate: this.props.chosenDate || new Date(),
      currentMonth: this.props.chosenDate || new Date(),
    };
  }

  componentDidUpdate(prevProps, prevStates, snapshot) {
    if (snapshot.triggerCalculateCurrMonth) {
      const diffDays = moment(
        moment(this.props.chosenDate).format("YYYY-MM-DD")
      ).diff(moment(this.state.currentMonth).format("YYYY-MM-DD"), "days");
      const currentMonthAfterAddDiff = moment(this.state.currentMonth).add(
        diffDays,
        "days"
      );

      if (
        !moment(moment(this.state.currentMonth).format("YYYY-MM")).isSame(
          moment(currentMonthAfterAddDiff.format("YYYY-MM"))
        )
      ) {
        this.setState({ currentMonth: currentMonthAfterAddDiff.toDate() });
      }
    }
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const snapshot = {};
    if (
      !moment(moment(prevProps.chosenDate).format("YYYY-MM-DD")).isSame(
        moment(this.props.chosenDate).format("YYYY-MM-DD")
      )
    ) {
      snapshot.triggerCalculateCurrMonth = true;
    }

    return snapshot;
  }

  renderHeader = () => {
    const { currentMonth } = this.state;
    return (
      <div className="light-calendar__header">
        <p>{moment(currentMonth).format("MM月 YYYY")}</p>
        <div className="light-calendar__navigate">
          <svg
            className="light-calendar__naviagte-prev-button"
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
            onClick={() => {
              this.setState((prev) => ({
                currentMonth: moment(prev.currentMonth).add(-1, "month"),
              }));
            }}
          >
            <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
            <path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z" />
          </svg>
          <svg
            className="light-calendar__naviagte-next-button"
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
            onClick={() => {
              this.setState((prev) => ({
                currentMonth: moment(prev.currentMonth).add(1, "month"),
              }));
            }}
          >
            <g>
              <path d="M0,0h24v24H0V0z" fill="none" />
            </g>
            <g>
              <polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12" />
            </g>
          </svg>
        </div>
      </div>
    );
  };
  renderDay = () => {
    const startDay = moment().startOf("month").startOf("week");
    const days = [];
    for (let i = 0; i < 7; ++i) {
      const day = startDay.clone().add(i, "day");
      days.push(
        <div key={day} className="light-calendar__day">
          {day.format("dd")}
        </div>
      );
    }

    return <div className="light-calendar__days-row">{days}</div>;
  };

  renderDate = () => {
    const { currentMonth } = this.state;

    const startDay = moment(currentMonth)
      .clone()
      .startOf("month")
      .startOf("week");
    const endDay = moment(currentMonth).clone().endOf("month").endOf("week");
    const day = startDay.clone();
    const calendar = [];
    const weeks = [];

    while (day.isBefore(endDay, "day")) {
      const dates = [];
      for (let i = 0; i < 7; ++i) {
        let disabled = false;
        let style = "";
        const dayClone = day.clone();

        const isToday = moment(day.format("YYYY-MM-DD")).isSame(
          moment().format("YYYY-MM-DD")
        );
        const isPast = moment(day.format("YYYY-MM-DD")).isBefore(
          moment().format("YYYY-MM-DD")
        );
        const isChosenDate = moment(
          moment(this.props.chosenDate).format("YYYY-MM-DD") ||
            moment(new Date()).format("YYYY-MM-DD")
        ).isSame(dayClone.format("YYYY-MM-DD"));

        const isSameMonth = moment(
          moment(this.state.currentMonth).format("YYYY-MM")
        ).isSame(dayClone.format("YYYY-MM"));

        if (this.props.disabledDate) {
          disabled = this.props.disabledDate(dayClone);
        }

        if (this.props.styledDate) {
          style = this.props.styledDate(dayClone);
        }

        if (this.props.disableNotCurrentMonth) {
          disabled = disabled || !isSameMonth;
        }

        dates.push(
          <button
            key={nanoid()}
            type="button"
            disabled={disabled}
            onClick={(e) => this.props.onClick(dayClone.toDate())}
            className={[
              "light-calendar__date",
              isToday ? "light-calendar__date--current-date" : "",
              isPast ? "light-calendar__date--past-date" : "",
              isChosenDate ? "light-calendar__date--chosen-date" : "",
              !isSameMonth ? "light-calendar__date--not-current-month" : "",
              style ? style : "",
            ]
              .join(" ")
              .toString()
              .replace(/ +(?= )/g, "")
              .trim()}
          >
            {moment(day).clone().format("DD")}
          </button>
        );

        day.add(1, "day");
      }
      weeks.push(
        <div key={nanoid()} className="light-calendar__week">
          {dates}
        </div>
      );
    }

    calendar.push(
      <div key={nanoid()} className="light-calendar__body">
        {weeks}
      </div>
    );

    return calendar;
  };
  render() {
    const { className } = this.props;
    const { renderHeader, renderDay, renderDate } = this;

    return (
      <div className={`light-calendar ${className || ""}`.trim()}>
        {renderHeader()}
        {renderDay()}
        {renderDate()}
      </div>
    );
  }
}

Calendar.propTypes = {
  className: PropTypes.string,
  chosenDate: PropTypes.instanceOf(Date),
};

export default Calendar;
