import request from "./../../core/api";

export const getShopMenu = (shopId) => {
  return request({
    url: `/shop/${shopId}/menu/`,
    method: "get",
  });
};

export const getShopStaff = (shopId) => {
  return request({
    url: `/shop/${shopId}/staff/`,
    method: "get",
  });
};

export const getShopDiscount = (shopId) => {
  return request({
    url: `/shop/${shopId}/discount/`,
    method: "get",
  });
};

export const getShopDetail = (shopId) => {
  return request({
    url: `/shop/${shopId}/`,
    method: "get",
  });
};
