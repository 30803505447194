import React, { Component } from "react";
import "./index.scss";

class TopButton extends Component {
  render() {
    return (
      <button
        className={`common-return-top-button ${
          this.props.size ? this.props.size : ""
        }`.trim()}
        onClick={this.props.onClick}
      >
        {this.props.buttonText ? this.props.buttonText : "TOPへ戻る"}
      </button>
    );
  }
}

export default TopButton;
