import * as types from "./types";

export const getShopInfo = (payload) => ({
  type: types["GET_SHOP_INFO"],
  payload,
});

export const getFullShopInfo = (payload) => ({
  type: types["GET_FULL_SHOP_INFO"],
  payload,
});

export const setToken = (payload) => ({
  type: types["SET_TOKEN"],
  payload,
});

export const setRefreshToken = (payload) => ({
  type: types["SET_REFRESH_TOKEN"],
  payload,
});

export const setUser = (payload) => ({
  type: types["SET_USER"],
  payload,
});
