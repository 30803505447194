import { call, put, all, takeLatest } from "redux-saga/effects";
import { setListMenu, setListStaff } from "./actions";
import { getListMenu, getListStaff } from "./api";
import { GET_LIST_MENU, GET_LIST_STAFF } from "./types";

function* handleGetListMenu(action) {
  try {
    const result = yield call(getListMenu, action.payload);
    const menus = result.data;
    yield put(setListMenu(menus));
  } catch (e) {
    // yield put(actions.loginError());
    throw e;
  }
}

function* handleGetListSaff(action) {
  try {
    const result = yield call(getListStaff, action.payload);
    const menus = result.data;
    yield put(setListStaff(menus));
  } catch (e) {
    // yield put(actions.loginError());
    throw e;
  }
}

function* appSaga() {
  yield all([
    takeLatest(GET_LIST_MENU, handleGetListMenu),
    takeLatest(GET_LIST_STAFF, handleGetListSaff),
  ]);
}

export default appSaga;
