import { combineReducers } from 'redux';
import { reducer } from './../routers';
import modalReducer from './../modules/modal/reducer';

export default function createReducer(asyncReducer){
  return combineReducers({
    ...reducer,
    ...asyncReducer,
    modal: modalReducer
  });
}
