import React, { Component } from "react";
import "./index.scss";
import has from "lodash/has";

class Dialog extends Component {
  constructor(props) {
    super(props);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }
  componentDidMount() {
    if (
      !(has(this.props, "closeByEscKey") && this.props.closeByEscKey === false)
    ) {
      window.addEventListener("keyup", this.handleKeyUp, false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false);
  }
  handleKeyUp(e) {
    const { onCloseRequest } = this.props;
    const keys = {
      27: () => {
        e.preventDefault();
        onCloseRequest(e);
        window.removeEventListener("keyup", this.handleKeyUp, false);
      },
    };

    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
  }
  overlayClick(e) {
    if (!this.props.closeOnOverlayClick) {
      return;
    }
    this.props.onCloseRequest(e);
  }
  render() {
    let { isShow, id, className } = this.props;
    return (
      <div
        id={id ? id : ""}
        className={[
          "dialog-overlay",
          "custom-style-scrollbar",
          className ? className : "",
          isShow ? "show-dialog" : "hide-dialog",
        ].join(" ")}
        onClick={(e) => this.overlayClick(e)}
      >
        <div className="dialog" onClick={(e) => e.stopPropagation()}>
          {has(this.props, "showCloseButton") &&
          this.props.showCloseButton === false ? (
            ""
          ) : (
            <span
              className="material-icons close-button"
              onClick={(e) => this.props.onCloseRequest(e)}
            >
              clear
            </span>
          )}
          <header>{this.props.title}</header>
          <main className="dialog-content">{this.props.children}</main>
        </div>
      </div>
    );
  }
}

export default Dialog;
