import React, { useState } from "react";
import Dialog from "../../../../shared/dialog";
import "./index.scss";
import MyButton from "./../../../../shared/buttons/primary";

const DeleteDialog = (props) => {
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  async function deleteStaff() {
    setIsBtnDisabled(true);

    window
      .axios({
        url: `staff/${props.data.id}/`,
        method: "delete",
      })
      .then(() => {
        onCloseRequest(props.data);
      });
  }

  function onCloseRequest(data) {
    props.onCloseRequest(data);
  }

  return (
    <Dialog
      id="delete-dialog"
      className={"staff-delete-dialog"}
      title={"削除"}
      isShow={props.isShow}
      onCloseRequest={() => onCloseRequest({})}
      closeOnOverlayClick={true}
    >
      <div>
        <h2 className="text-center">削除でよろしいですか？</h2>
        <div className="booking-form__btns">
          <MyButton
            className="me-1"
            myVariant="cancel"
            disabled={isBtnDisabled}
            onClick={() => onCloseRequest({})}
          >
            キャンセル
          </MyButton>

          <MyButton
            myVariant="delete"
            isLoading={isBtnDisabled}
            onClick={deleteStaff}
          >
            削除
          </MyButton>
          {/* <span
            type="button"
            className="cancel-button me-1"
            onClick={() => onCloseRequest({})}
          >
            キャンセル
          </span>
          <span type="button" className="delete-button" onClick={deleteStaff}>
            削除
          </span> */}
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
