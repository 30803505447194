import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { matchPath } from "react-router";
import { routes } from "../../../routers";
import * as actions from "../../../modules/user/actions";
import defaultImage from "../../../assets/images/default-images-empty.png";
import "./index.scss";

const USER_HISTORY_PATH = "/:id/booking/history";

class Header extends Component {
  constructor(props) {
    super(props);
    this.dispatch = props.dispatchActions;
    this.state = {
      shopId: +this.getShopId(window.location.pathname)[0],
      currentRoute: "",
    };

    this.unlistener = this.props.history.listen(async (location) => {
      const currentRoute = routes.find((route) =>
        matchPath(location.pathname, route)
      );

      this.setState((prevState) => {
        return {
          ...prevState,
          currentRoute,
        };
      });
    });

    this.dispatch.getShopDetail(this.state.shopId);
  }

  componentDidMount() {
    this.setState({ currentRoute: this.getCurrentRoute() }, () => {
      if (
        this.state.currentRoute.path === USER_HISTORY_PATH &&
        !this.props.user.userToken
      ) {
        this.props.history.push(`/booking/user/login`);
      }
    });
    this.dispatch.getShopMenu(this.state.shopId);
    this.dispatch.getShopStaff(this.state.shopId);
    this.dispatch.getShopDiscount(this.state.shopId);
  }

  getCurrentRoute = () => {
    return routes.find((route) =>
      matchPath(this.props.history.location.pathname, route)
    );
  };

  getShopId = (path) => {
    return path.match(/\/(\d+)+[\\/]?/g).map((id) => id.replace(/\//g, ""));
  };

  gotoTop = () => {
    this.props.history.push(`/${this.state.shopId}/booking`);
  };

  componentWillUnmount() {
    this.unlistener();
  }

  signOut = () => {
    this.dispatch.signOut();
    this.props.history.push(`/${this.state.shopId}/booking`);
  };

  render() {
    const { gotoTop, signOut } = this;
    const { shopDetail, userInfo } = this.props.user;
    const { currentRoute } = this.state;

    return (
      <header className="user-header fade-out" style={this.props.style}>
        <div
          className={[
            "user-header__wrapper",
            currentRoute.path === USER_HISTORY_PATH
              ? "user-header__wrapper--user"
              : "",
          ].join(" ")}
        >
          {currentRoute.path !== USER_HISTORY_PATH ? (
            <>
              <figure className="user-header__avatar" onClick={gotoTop}>
                {Object.keys(shopDetail).length ? (
                  <img
                    src={shopDetail.photos[0]?.url || defaultImage}
                    alt="cippo"
                  />
                ) : (
                  ""
                )}
              </figure>
              {Object.keys(shopDetail).length ? (
                <div className="user-header__name">
                  <h2 className="user-header__name--eng">{shopDetail.name}</h2>
                </div>
              ) : (
                ""
              )}
              {Object.keys(userInfo).length &&
              Object.keys(shopDetail).length ? (
                <div className="user-header__logout" onClick={signOut}>
                  <span className="material-icons">logout</span>
                  <p>サインアウト</p>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              {/* <figure className="user-header__avatar" onClick={gotoTop}>
                {Object.keys(userInfo).length ? (
                  <img src={userInfo.avatar || defaultImage} alt="cippo" />
                ) : (
                  ""
                )}
              </figure> */}
              {Object.keys(userInfo).length ? (
                <>
                  <figure className="user-header__avatar" onClick={gotoTop}>
                    {Object.keys(userInfo).length ? (
                      <img src={userInfo.icon || defaultImage} alt="cippo" />
                    ) : (
                      ""
                    )}
                  </figure>
                  <div className="user-header__name">
                    <h2 className="user-header__name--eng">
                      {userInfo.username}
                    </h2>
                  </div>
                  <div className="user-header__logout" onClick={signOut}>
                    <span className="material-icons">logout</span>
                    <p>サインアウト</p>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* {Object.keys(userInfo).length ? (
                <div className="user-header__logout" onClick={signOut}>
                  <span className="material-icons">logout</span>
                  <p>サインアウト</p>
                </div>
              ) : (
                ""
              )} */}
            </>
          )}
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
