import Button from "@material-ui/core/Button";
import React, { Component } from "react";
import PropTypes from "prop-types";

import "./index.scss";

class PrimaryButton extends Component {
  render() {
    const { className, myVariant, children, isLoading, onClick, disabled } =
      this.props;
    const cName = `primary-button ${className || ""} ${
      myVariant ? `primary-button--${myVariant}` : ""
    } ${isLoading ? "primary-button--is-loading" : ""}`
      .trim()
      .replace(/\s\s+/g, " ");

    const cVariant = `${myVariant !== "cancel" ? "contained" : "outlined"}`;

    return (
      <Button
        className={cName}
        variant={cVariant}
        color="primary"
        onClick={onClick}
        disabled={isLoading || disabled}
      >
        {children}
      </Button>
    );
  }
}

PrimaryButton.propTypes = {
  className: PropTypes.string,
  myVariant: PropTypes.string,
};

export default PrimaryButton;
