import iconUser from "../../../assets/icons/ic-user.png";
import defaultAvatar from "../../../assets/images/default-avatar.png";
import "./index.scss";
import * as actions from "./../../../modules/user/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import utils from "../../../core/utils";
import ReturnTopButton from "../../shared/top-button";
import cloneDeep from "lodash/cloneDeep";
import intersection from "lodash/intersection";

import React, { Component } from "react";

class Content extends Component {
  constructor(props) {
    super(props);
    this.dispatch = props.dispatchActions;

    this.state = {
      shopId: props.match.params.id,
      listFilteredStaff: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.initView();
  }

  initView = () => {
    const { chosenMenu } = this.props.user.userBookingData;
    const { shopStaffs } = this.props.user;

    if (chosenMenu) {
      const menuIds = chosenMenu.split(",").map((item) => +item);

      const listFilteredStaff = cloneDeep(shopStaffs).filter((staff) => {
        const staffMenuIds = cloneDeep(staff.menus).map((item) => item.id);
        let intersectionMenus = intersection(menuIds, staffMenuIds);
        if (intersectionMenus.length) {
          return staff;
        } else {
          return false;
        }
      });

      this.setState((prev) => ({
        ...prev,
        listFilteredStaff,
      }));
    } else {
      this.setState((prev) => ({
        ...prev,
        listFilteredStaff: shopStaffs,
      }));
    }
  };

  choseStaff = (staff) => {
    const { chosenMenu } = this.props.user.userBookingData;
    const { shopStaffs, shopMenus, shopDiscounts } = this.props.user;

    const menuIds = cloneDeep(chosenMenu)
      .split(",")
      .map((i) => +i);

    if (this.props.user.userBookingData.chosenStaff === staff) {
      this.props.history.push(`/${this.props.match.params.id}/booking`);
      return;
    }

    const staffMenus = shopStaffs.find((s) => s.id === staff).menus;
    const staffMenuIds = cloneDeep(staffMenus).map((i) => i.id);

    const intersectionMenus = intersection(menuIds, staffMenuIds);
    const newChosenMenuIds = intersectionMenus.join(",");

    const newChosenMenu = cloneDeep(shopMenus).filter((menu) =>
      intersectionMenus.includes(menu.id)
    );

    const data = utils.calculateBookingPrice(newChosenMenu, shopDiscounts);

    const userBookingData = {
      ...this.props.user.userBookingData,
      chosenStaff: staff,
      chosenMenu: newChosenMenuIds,
      ...data,
    };
    this.dispatch.setUserBookingData({
      key: this.state.shopId,
      value: userBookingData,
    });

    this.props.history.push(`/${this.props.match.params.id}/booking`);
  };

  gotoTop = () => {
    this.props.history.push(`/${this.props.match.params.id}/booking`);
  };

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }
  render() {
    const { choseStaff, gotoTop } = this;
    const { listFilteredStaff } = this.state;
    // const { shopStaffs } = this.props.user;
    const { chosenStaff } = this.props.user.userBookingData;

    return (
      <div id="user-booking-staffs" className="user-staffs fade-out">
        <div className="user-staffs__wrapper">
          <div className="user-staffs__ttl user-menus__ttl">
            <section>
              <figure>
                <img src={iconUser} alt="cippo-booking"></img>
              </figure>
              <p>
                {window.store.getState().user.shopDetail.section_name_1}
                を選択する
              </p>
            </section>
            <ReturnTopButton onClick={gotoTop} />
          </div>
          <p className="user-staffs__note">
            ※選択した{window.store.getState().user.shopDetail.section_name_1}
            によってメニューが絞り込まれます。
          </p>
          <div className="user-staffs__ctn-wrapper">
            <ul className="user-staffs__list-staff">
              {listFilteredStaff.map((item) => (
                <li
                  key={item.id}
                  className={[
                    "user-staffs__staff-info",
                    item.id === +chosenStaff ? "active" : "",
                  ].join(" ")}
                  onClick={() => choseStaff(item.id)}
                >
                  <figure>
                    <img
                      src={
                        item.media_type === "image"
                          ? item.image || defaultAvatar
                          : utils.getIdLinkYoutube(item.image)
                          ? `https://img.youtube.com/vi/${
                              utils.getIdLinkYoutube(item.image)[1]
                            }/0.jpg`
                          : defaultAvatar
                      }
                      alt="cippo-booking"
                    ></img>
                  </figure>
                  <p className="user-staffs__staff-name">
                    {window._.truncate(item.name, {
                      length: 20,
                      omission: "...",
                    })}
                  </p>
                  <p className="user-staffs__staff-desc">
                    {window._.truncate(item.detail, {
                      length: 50,
                      omission: "...",
                    })}
                  </p>
                </li>
              ))}
            </ul>

            <ReturnTopButton size={"large"} onClick={gotoTop} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
