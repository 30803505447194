import Immutable from "seamless-immutable";
import * as types from "./types";

const initialState = Immutable({
  listMenu: [],
  isLoadMenuDone: false,
  listStaff: [],
  isLoadStaffDone: false,
});

const setListMenu = (action, state) => {
  const { payload: listMenu } = action;
  return state.merge({ listMenu, isLoadMenuDone: true });
};

const setListStaff = (action, state) => {
  const { payload: listStaff } = action;
  return state.merge({ listStaff, isLoadStaffDone: true });
};

const dispatch = {
  [types.SET_LIST_MENU]: setListMenu,
  [types.SET_LIST_STAFF]: setListStaff,
};

const reducer = (state, action) => {
  if (typeof state === "undefined") {
    return initialState;
  }
  return typeof dispatch[action.type] === "function"
    ? dispatch[action.type](action, state)
    : state;
};

export default reducer;
