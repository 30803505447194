import React, { Component } from "react";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import * as actions from "./../../modules/auth/actions";
import * as bookingActions from "./../../modules/booking/actions";
import * as settingActions from "./../../modules/setting/actions";
import * as userActions from "./../../modules/user/actions";
import { bindActionCreators } from "redux";
import { routes } from "../../routers";
import UserHeader from "./user";
import constant from "./../../core/constant";
import AduserSideBar from "./aduser";
import "./index.scss";
import { withRouter } from "react-router-dom";

const whiteListUserPath = [
  "/booking/user/login",
  "/user-booking/redirect",
  "/booking/term-of-service",
];

const BOOKING_PATH = "/booking";

class BaseLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRoute: "",
    };

    // Listening path change
    this.unlistener = props.history.listen(async (location) => {
      const currentRoute = routes.find((route) =>
        matchPath(location.pathname, route)
      );

      if (currentRoute.path === BOOKING_PATH) {
        this.props.dispatchActions.setSimpleCalendarChosenDate(new Date());
      }

      if (!currentRoute) return props.history.push("/404");

      this.setState((prevState) => {
        return {
          ...prevState,
          currentRoute,
        };
      });
    });
  }

  async componentDidMount() {
    // Listen path change when component mounted
    const currentRoute = routes.find((route) =>
      matchPath(this.props.history.location.pathname, route)
    );

    if (currentRoute && currentRoute.path === BOOKING_PATH) {
      this.props.dispatchActions.setSimpleCalendarChosenDate(new Date());
    }

    if (!currentRoute) return this.props.history.push("/404");

    this.setState((prevState) => {
      return {
        ...prevState,
        currentRoute,
      };
    });
  }

  componentWillUnmount() {
    this.unlistener();
  }

  render() {
    const { currentRoute } = this.state;
    const {
      isLoadMenuDone,
      isLoadStaffDone,
      isLoadDiscountDone,
      isLoadShopDetailDone,
      isLoadUserBookingDataDone,
    } = this.props.user;
    const isLoadingDataUserDone =
      isLoadMenuDone &&
      isLoadStaffDone &&
      isLoadDiscountDone &&
      isLoadShopDetailDone &&
      isLoadUserBookingDataDone;
    return (
      <>
        {currentRoute && currentRoute.type === constant.ROUTE_TYPE_ADUSER ? (
          currentRoute.protected ? (
            <>
              <AduserSideBar
                currentRoute={currentRoute}
                title={this.props.auth.user.name}
                {...this.props}
              />

              {{ ...this.props.children }}
            </>
          ) : (
            { ...this.props.children }
          )
        ) : (
          ""
        )}

        {currentRoute && currentRoute.type === constant.ROUTE_TYPE_USER ? (
          !whiteListUserPath.includes(currentRoute.path) ? (
            <div className="user-booking-lt">
              <UserHeader
                style={
                  currentRoute.path === "/:id/booking/user/register"
                    ? { display: "none" }
                    : {}
                }
                {...this.props}
              />
              {isLoadingDataUserDone ? { ...this.props.children } : ""}
            </div>
          ) : (
            { ...this.props.children }
          )
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    booking: state.booking,
    setting: state.setting,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators(
    { ...actions, ...userActions, ...settingActions, ...bookingActions },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseLayout)
);
