import constant from "./constant";

const utils = {
  statusMasterData: {
    waiting: "予約待ち",
    approved: "予約済み",
    processing: "進行中",
    done: "完了",
    cancelled: "予約キャンセル",
  },

  formatMoney(number, n, x, s, c) {
    let re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
      num = number.toFixed(Math.max(0, ~~n));

    return (c ? num.replace(".", c) : num).replace(
      new RegExp(re, "g"),
      "$&" + (s || ",")
    );
  },

  validateYoutubeLink(link) {
    const regex =
      //eslint-disable-next-line
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    return !!link.match(regex);
  },

  getIdLinkYoutube(link) {
    const regex =
      //eslint-disable-next-line
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    return link.match(regex);
  },

  validateImage(file) {
    const validate = {
      isValid: true,
      error: "",
    };
    const mimetype = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
    if (file.size > constant.MAX_SIZE_IMAGE) {
      validate.isValid = false;
      validate.error = "画像を15MB以内アップロードしてください。";
      return validate;
    }

    if (!mimetype.includes(file.type)) {
      validate.isValid = false;
      validate.error = "ファイル形式が間違いました。";
      return validate;
    }

    return validate;
  },

  isEmptyObject: function (object) {
    return !!!Object.keys(object).length;
  },

  validate: async function (dataObject, validateSchema, customValidate) {
    const errorKeys = [];
    let isValid;

    for (let item of Object.keys(validateSchema)) {
      if (Array.isArray(dataObject[item]) && !dataObject[item].length) {
        errorKeys.push(item);
        break;
      }
      if (!dataObject[item]) errorKeys.push(item);
    }

    isValid = errorKeys.length ? false : true;
    if (typeof customValidate === "object") {
      Object.keys(customValidate).forEach((item) => {
        if (dataObject[item]) {
          const { isValid: customValid } = customValidate[item](
            dataObject[item]
          );
          isValid = isValid && customValid;
          errorKeys.push(item.toString());
        }
      });
    }

    return {
      isValid,
      errors: errorKeys,
    };
  },

  uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    return window.axios({
      url: "upload/",
      method: "put",
      header: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  },

  calculateBookingPrice(listChosenMenu, shopDiscounts) {
    const totalMoney = listChosenMenu.reduce((acc, curr) => {
      return (acc += Number(curr.price));
    }, 0);

    const totalDuration = listChosenMenu.reduce((acc, curr) => {
      return (acc += Number(curr.duration));
    }, 0);

    const totalDiscount = listChosenMenu.reduce((acc, curr) => {
      const discount = shopDiscounts.find((item) => curr.discount === item.id);

      let amount = acc;

      if (discount) {
        if (discount.type === "Percentage") {
          amount += (+discount.value * +curr.price) / 100;
        } else {
          amount += +discount.value;
        }
      }
      return amount;
    }, 0);

    return {
      total: totalMoney,
      discount: totalDiscount,
      duration: totalDuration,
    };
  },
};
export default utils;
