import Booking from "./../../controllers/booking";
import reducer from "./../../modules/booking/reducer";
import constant from "./../../core/constant";
import sagas from "./../../modules/booking/sagas";

const routes = [
  {
    name: "booking",
    path: "/booking",
    component: Booking,
    protected: true,
    exact: true,
    reducer,
    sagas,
    type: constant.ROUTE_TYPE_ADUSER,
  },
];

export default routes;
