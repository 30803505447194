import React, { Component } from "react";
import Modal from "./../../../shared/dialog";
import schema from "./validate";
import request from "../../../../core/api";
import * as actions from "./../../../../modules/user/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

class loginModal extends Component {
  constructor(props) {
    super(props);

    this.dispatch = this.props.dispatchActions;

    this.state = {
      userAccount: "",
      userPassword: "",
      errorMessages: [],
      isBtnDisabled: false,
      showModalCloseBtn: true,
      isCredentialWrong: false,
    };
  }

  login = async () => {
    await this.setState({
      isBtnDisabled: true,
      errorMessages: [],
      isCredentialWrong: false,
    });

    const { userAccount, userPassword } = this.state;
    const { error } = schema.validate({
      userAccount,
      userPassword,
    });

    if (error) {
      const errorMessages = error.details.map((item) => ({
        name: item.context.label,
        message: item.message,
      }));

      this.setState({ errorMessages, isBtnDisabled: false });
      return;
    }

    await this.setState({ showModalCloseBtn: false });

    request({
      url: "token/",
      method: "post",
      data: {
        username: userAccount,
        password: userPassword,
      },
    })
      .then((result) => {
        const { access, user, refresh } = result.data;
        this.dispatch.setUserToken(access);
        this.dispatch.setUserRefreshToken(refresh);
        this.dispatch.setUserInfo(user);
        this.props.onClose({ loginSuccess: true });
      })
      .catch(() => {
        this.setState({
          isCredentialWrong: true,
          isBtnDisabled: false,
          showModalCloseBtn: true,
        });
      });
  };

  validateOnBlur = (e) => {
    let errorList;
    const {
      error: { details },
    } = schema.validate({
      [e.target.name]: e.target.value,
    });

    errorList = details;

    let errorMsgState = this.state.errorMessages;
    const error = errorList.find(
      (item) => item.context.label === e.target.name
    );

    if (!error) {
      const errorIdx = errorMsgState.findIndex(
        (error) => error.name === e.target.name
      );
      if (errorIdx !== -1) {
        errorMsgState.splice(errorIdx, 1);
      }
    } else {
      const item = errorMsgState.find(
        (item) => item.name === error.context.label
      );

      if (!item) {
        errorMsgState.push({
          name: e.target.name,
          message: error.message,
        });
      } else {
        errorMsgState = errorMsgState.map((item) => {
          if (item.name === error.context.label) {
            return {
              name: e.target.name,
              message: error.message,
            };
          }

          return item;
        });
      }
    }

    this.setState((prev) => ({
      ...prev,
      errorMessages: errorMsgState,
    }));
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.login();
    }
  };

  render() {
    const {
      userAccount,
      userPassword,
      errorMessages,
      isBtnDisabled,
      showModalCloseBtn,
      isCredentialWrong,
    } = this.state;
    const { login, validateOnBlur, handleKeyDown } = this;

    return (
      <Modal
        id="login-dialog"
        className={"login-dialog"}
        title={"CiPPoアカウントでログインする"}
        isShow={true}
        closeByEscKey={false}
        showCloseButton={showModalCloseBtn}
        onCloseRequest={() => this.props.onClose()}
        closeOnOverlayClick={false}
      >
        {isCredentialWrong ? (
          <p
            style={{ marginBottom: "2rem", fontSize: "12px" }}
            className="error-text"
          >
            ユーザー名もしくはパスワードが一致しません
          </p>
        ) : (
          ""
        )}

        <div className="mb-3 booking-form__row required">
          <div className="booking-form__label">
            <label className="form-label user-select-none">ユーザー名</label>
          </div>
          <div className="booking-form__control">
            <input
              className="form-control"
              maxLength="64"
              value={userAccount}
              name="userAccount"
              onBlur={validateOnBlur}
              onKeyDown={handleKeyDown}
              onChange={(e) =>
                this.setState((prev) => {
                  return {
                    ...prev,
                    userAccount: !e.target.value.trim() ? "" : e.target.value,
                  };
                })
              }
            />
            {errorMessages.find((item) => item.name === "userAccount") && (
              <p className="error-text">
                {
                  errorMessages.find((item) => item.name === "userAccount")
                    .message
                }
              </p>
            )}
          </div>
        </div>

        <div className="mb-3 booking-form__row required">
          <div className="booking-form__label">
            <label className="form-label user-select-none">パスワード</label>
          </div>
          <div className="booking-form__control">
            <input
              className="form-control"
              maxLength="64"
              type="password"
              value={userPassword}
              name="userPassword"
              onBlur={validateOnBlur}
              onKeyDown={handleKeyDown}
              onChange={(e) =>
                this.setState((prev) => {
                  return {
                    ...prev,
                    userPassword: !e.target.value.trim() ? "" : e.target.value,
                  };
                })
              }
            />
            {errorMessages.find((item) => item.name === "userPassword") && (
              <p className="error-text">
                {
                  errorMessages.find((item) => item.name === "userPassword")
                    .message
                }
              </p>
            )}
          </div>
        </div>

        <div className="login-btn">
          <button disabled={isBtnDisabled} type="button" onClick={login}>
            ログイン
          </button>

          <Link
            to={`/${this.props.match.params.id}/booking/user/register`}
            className="register-btn"
          >
            新しいアカウントを登録する
          </Link>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(loginModal);
