import React, { Component, useEffect, useState } from "react";
import baseContentLayout from "./../../shared/base-content/index";
import "./index.scss";
import CreateDialog from "./dialog/create";
import EditDialog from "./dialog/edit";
import DeleteDialog from "./dialog/delete";
import MyButton from "./../../shared/buttons/primary";

import * as actions from "./../../../modules/setting/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const Content = (props) => {
  const globalState = window.store.getState();
  const [listMenu, setListMenu] = useState(1);
  const [createDialogShow, setCreateDialogShow] = useState(false);
  const [editDialogShow, setEditDialogShow] = useState(false);
  const [deleteDialogShow, setDeleteDialogShow] = useState(false);
  const [dialogData, setDialogData] = useState({});

  useEffect(() => {
    setListMenu(globalState.setting.listMenu);
  }, [globalState.setting.listMenu]);

  async function editBtnClick(data) {
    await setDialogData(data);
    setEditDialogShow(true);
  }

  async function deleteBtnClick(data) {
    await setDialogData(data);
    setDeleteDialogShow(true);
  }

  function reloadMenu() {
    const { dispatchActions } = props;
    dispatchActions.getListMenu(props.auth.user.id);
  }

  function onCloseEditDialog(data) {
    setEditDialogShow(false);
    if (!window.utils.isEmptyObject(data)) {
      reloadMenu();
    }
  }

  function onCloseCreateDialog(data) {
    setCreateDialogShow(false);
    if (!window.utils.isEmptyObject(data)) {
      reloadMenu();
    }
  }

  function onCloseDeleteDialog(data) {
    setDeleteDialogShow(false);
    if (!window.utils.isEmptyObject(data)) {
      reloadMenu();
    }
  }

  async function createBtnClick() {
    await setDialogData({});
    setCreateDialogShow(true);
  }

  return (
    <div className="menu">
      <div className="menu__search mb-4">
        {/* <span type="button" className="add-button" onClick={createBtnClick}>
          追加
        </span> */}
        <MyButton myVariant="add" onClick={createBtnClick}>
          追加
        </MyButton>
      </div>
      <div className="history__table menu__table">
        <table className="table">
          <thead className="">
            <tr>
              <th scope="col" className="col-username">
                メニュー名
              </th>
              <th scope="col" className="col-service">
                説明
              </th>
              <th scope="col" className="col-time">
                価格
              </th>
              <th scope="col" className="col-status">
                時間
              </th>
              <th scope="col" className="col-activity">
                操作
              </th>
            </tr>
          </thead>
          <tbody>
            {listMenu.length ? (
              listMenu.map((item) => (
                <tr key={item.id}>
                  <td>
                    {window._.truncate(item.name, {
                      length: 30,
                      omission: "...",
                    })}
                  </td>
                  <td>
                    {window._.truncate(item.detail, {
                      length: 30,
                      omission: "...",
                    })}
                  </td>
                  <td>{item.price.split(".")[0]} 円</td>
                  <td>{item.duration}分</td>
                  <td className="buttons">
                    {/* <span
                      type="button"
                      className="edit-button me-2"
                      onClick={() => editBtnClick(item)}
                    >
                      編集
                    </span> */}
                    <MyButton
                      className="me-2"
                      myVariant="edit"
                      onClick={() => editBtnClick(item)}
                    >
                      編集
                    </MyButton>
                    <MyButton
                      myVariant="delete"
                      onClick={() => deleteBtnClick(item)}
                    >
                      削除
                    </MyButton>
                    {/* <span
                      type="button"
                      className="delete-button me-2"
                      onClick={() => deleteBtnClick(item)}
                    >
                      削除
                    </span> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">メニューが有りません</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {createDialogShow && (
        <CreateDialog
          type={0}
          isShow={createDialogShow}
          onCloseRequest={onCloseCreateDialog}
          data={dialogData}
        ></CreateDialog>
      )}
      {editDialogShow && (
        <EditDialog
          type={0}
          isShow={editDialogShow}
          onCloseRequest={onCloseEditDialog}
          data={dialogData}
        ></EditDialog>
      )}
      {deleteDialogShow && (
        <DeleteDialog
          type={0}
          isShow={deleteDialogShow}
          onCloseRequest={onCloseDeleteDialog}
          data={dialogData}
        ></DeleteDialog>
      )}
    </div>
  );
};
const MenuContent = baseContentLayout(Content);

class index extends Component {
  render() {
    return <MenuContent name="メユー設定" {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
