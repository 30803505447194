import * as types from "./types";

export const getShopStaff = (payload) => ({
  type: types["GET_SHOP_STAFF"],
  payload,
});

export const setShopStaff = (payload) => ({
  type: types["SET_SHOP_STAFF"],
  payload,
});

export const getShopMenu = (payload) => ({
  type: types["GET_SHOP_MENU"],
  payload,
});

export const setShopMenu = (payload) => ({
  type: types["SET_SHOP_MENU"],
  payload,
});

export const getShopDiscount = (payload) => ({
  type: types["GET_SHOP_DISCOUNT"],
  payload,
});

export const setShopDiscount = (payload) => ({
  type: types["SET_SHOP_DISCOUNT"],
  payload,
});

export const setUserBookingData = (payload) => ({
  type: types["SET_USER_BOOKING_DATA"],
  payload,
});

export const getShopDetail = (payload) => ({
  type: types["GET_SHOP_DETAIL"],
  payload,
});

export const setShopDetail = (payload) => ({
  type: types["SET_SHOP_DETAIL"],
  payload,
});

export const setUserToken = (payload) => ({
  type: types["SET_USER_TOKEN"],
  payload,
});

export const setUserRefreshToken = (payload) => ({
  type: types["SET_USER_REFRESH_TOKEN"],
  payload,
});

export const setUserInfo = (payload) => ({
  type: types["SET_USER_INFO"],
  payload,
});

export const signOut = (payload) => ({
  type: types["SIGN_OUT"],
  payload,
});

export const setConfirmWhenRemove = (payload) => ({
  type: types["SET_CONFIRM_WHEN_REMOVE"],
  payload,
});
