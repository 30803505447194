import React, { Component } from "react";
import "./index.scss";
import getValidateSchema from "./validate";
import * as actions from "../../../modules/user/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import request from "../../../core/api";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import SuccessModal from "./success-modal";
import { listCity as listAllCity } from "./listCity";
import MyButton from "./../../shared/buttons/primary";
import moment from "moment";

class UserRegister extends Component {
  constructor(props) {
    super(props);
    this.dispatch = this.props.dispatchActions;
    this.state = {
      showPass: false,
      showConfirmPass: false,
      isInitialLoadDone: false,
      serverErrMsg: "",
      errorMessage: {},
      listPrefecture: [],
      listCity: [],
      shopDetail: {},
      isBtnDisabled: false,
      currentCity: "",
      isRegisterDone: false,
      genderOptions: [],
      submitData: {
        username: "",
        email: "",
        password: "",
        confirm_password: "",
        first_name: "",
        last_name: "",
        birthday: "",
        phone_number: "",
        gender: "",
        prefecture: "",
        city: "",
        town: "",
        street: "",
        house_number: "",
        confirmed: false,
      },
    };
  }

  componentDidMount() {
    this.initView().then(() => {
      this.setState({ isInitialLoadDone: true });
    });
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  initView = () => {
    return new Promise((resolve, reject) => {
      this.fecthPrefectures().then(({ data }) => {
        const listPrefecture = data.result;
        this.setState({ listPrefecture });
        const { shopDetail } = this.props.user;
        let defaultCity = "";

        this.setState({ currentCity: shopDetail.city_name });

        if (shopDetail.city_name_ja === "@here") {
          const submitData = { ...this.state.submitData, gender: "lesbian" };
          this.setState({
            submitData,
            genderOptions: [
              { value: "lesbian", label: "レズビアン" },
              { value: "gay", label: "ゲイ" },
              { value: "bisexsual", label: "バイセクシュアル" },
              { value: "ftm", label: "FTM" },
              { value: "mtf", label: "MTF" },
              { value: "unknown", label: "クエスチョニング" },
              { value: "other", label: "その他" },
            ],
          });
        } else {
          const submitData = { ...this.state.submitData, gender: "male" };
          this.setState({
            submitData,
            genderOptions: [
              { value: "male", label: "男性" },
              { value: "female", label: "女性" },
            ],
          });
        }

        defaultCity = listAllCity.find(
          (item) => item.city === shopDetail.city_name
        );

        const selectedPrefCode = listPrefecture.find(
          (item) => item.prefName === defaultCity.prefecture
        ).prefCode;

        const submitData = {
          ...this.state.submitData,
          prefecture: selectedPrefCode,
        };

        this.setState({ submitData });

        this.fecthCities({ prefCode: selectedPrefCode }).then(({ data }) => {
          const listCity = data.result;
          this.setState({ listCity });

          const selectedCityCode = listCity.find(
            (item) => item.cityName === defaultCity.cityName
          ).cityCode;
          const submitData = {
            ...this.state.submitData,
            city: selectedCityCode,
          };

          this.setState({ submitData });

          resolve();
        });
      });
    });
  };

  fecthPrefectures() {
    return request({
      baseURL: process.env.REACT_APP_OPEN_DATA_BASE_URL,
      url: "prefectures",
      headers: {
        common: {
          "X-API-Key": process.env.REACT_APP_OPEN_DATA_API_KEY,
        },
      },
    });
  }

  fecthCities(params) {
    return request({
      baseURL: process.env.REACT_APP_OPEN_DATA_BASE_URL,
      url: "cities",
      params,
      headers: {
        common: {
          "X-API-Key": process.env.REACT_APP_OPEN_DATA_API_KEY,
        },
      },
    });
  }

  validate = async () => {
    const errorMessage = {};
    await this.setState({ errorMessage, serverErrMsg: "" });

    const { error } = getValidateSchema().validate(this.state.submitData);
    if (error) {
      error.details.forEach((item) => {
        set(errorMessage, item.path, item.message);
      });

      await this.setState({ errorMessage });

      return false;
    }

    return true;
  };

  validateOnBlur = async (key) => {
    const errorMessage = this.state.errorMessage;
    errorMessage[key] = "";
    await this.setState({ errorMessage });

    const { error } = getValidateSchema(key).validate(this.state.submitData);

    if (error) {
      error.details.forEach((item) => {
        set(errorMessage, item.path, item.message);
      });

      await this.setState({ errorMessage });
    }
  };

  callApiRegister = (data) => {
    return request({
      baseURL: process.env.REACT_APP_CIPPO_BASE_API,
      url: this.state.currentCity + "/api/users/register",
      method: "post",
      data,
    });
  };

  register = async () => {
    const isValid = await this.validate();

    if (!isValid) {
      const errorTextNode = document.querySelector("p.error-text");
      errorTextNode.parentNode.previousElementSibling.scrollIntoView();
      return;
    }

    this.setState({ isBtnDisabled: true });

    const { listCity, listPrefecture } = this.state;

    const submitData = cloneDeep(this.state.submitData);

    if (submitData.city) {
      submitData.city = listCity.find(
        (item) => item.cityCode === submitData.city
      ).cityName;
    }
    if (submitData.prefecture) {
      submitData.prefecture = listPrefecture.find(
        (item) => item.prefCode === +submitData.prefecture
      ).prefName;
    }
    if (!submitData.birthday) {
      delete submitData.birthday;
    }

    delete submitData.confirm_password;
    delete submitData.confirmed;

    this.callApiRegister(submitData)
      .then(() => {
        request({
          url: "token/",
          method: "post",
          data: {
            username: submitData.username,
            password: submitData.password,
          },
        }).then((result) => {
          const { access, user, refresh } = result.data;
          this.dispatch.setUserToken(access);
          this.dispatch.setUserRefreshToken(refresh);
          this.dispatch.setUserInfo(user);
          this.setState({ isRegisterDone: true });
        });
      })
      .catch(async (err) => {
        const { status, data } = err.response;

        if (status === 400) {
          await this.setState({ serverErrMsg: data.message });
          const errorTextNode = document.querySelector("p.error-text");
          errorTextNode.previousElementSibling.scrollIntoView();
          this.setState({ isBtnDisabled: false });
        }
      });
  };

  render() {
    const {
      errorMessage,
      isBtnDisabled,
      submitData,
      listPrefecture,
      listCity,
      isRegisterDone,
      serverErrMsg,
      genderOptions,
      isInitialLoadDone,
      showPass,
      showConfirmPass,
    } = this.state;
    const { shopDetail } = this.props.user;
    const { validateOnBlur, fecthCities, register } = this;

    return (
      <div className="user-booking-login user-booking-register">
        <div className="user-booking-login__wrapper user-booking-register__wrapper">
          <h2>CiPPoアカウントを登録する</h2>
          {serverErrMsg && (
            <p
              style={{
                marginBottom: "2rem",
                textAlign: "center",
                lineHeight: "1.2",
              }}
              className="error-text"
            >
              {serverErrMsg}
            </p>
          )}
          <div className="mb-3 booking-form__row required">
            <div className="booking-form__label">
              <label className="form-label user-select-none">
                ユーザー名（公開）
              </label>
            </div>
            <div className="booking-form__control">
              <input
                className="form-control"
                maxLength="64"
                value={submitData.username}
                onBlur={() => validateOnBlur("username")}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\s/g, "");
                }}
                onChange={(e) => {
                  const submitData = {
                    ...this.state.submitData,
                    username: !e.target.value.trim() ? "" : e.target.value,
                  };
                  this.setState({ submitData: submitData });
                }}
              />
              {errorMessage.username && (
                <p className="error-text">{errorMessage.username}</p>
              )}
            </div>
          </div>

          <div className="mb-3 booking-form__row required">
            <div className="booking-form__label">
              <label className="form-label user-select-none">
                メールアドレス
              </label>
            </div>
            <div className="booking-form__control">
              <input
                className="form-control"
                maxLength="64"
                value={submitData.email}
                onBlur={() => validateOnBlur("email")}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\s/g, "");
                }}
                onChange={(e) => {
                  const submitData = {
                    ...this.state.submitData,
                    email: !e.target.value.trim() ? "" : e.target.value,
                  };
                  this.setState({ submitData: submitData });
                }}
              />
              {errorMessage.email && (
                <p className="error-text">{errorMessage.email}</p>
              )}
            </div>
          </div>

          <div className="mb-3 booking-form__row required">
            <div className="booking-form__label">
              <label className="form-label user-select-none">パスワード</label>
            </div>
            <div className="booking-form__control">
              <div className="user-booking-register__password-field">
                <input
                  className="form-control"
                  maxLength="16"
                  type={!showPass ? "password" : "text"}
                  placeholder="8文字以上で入力してください。"
                  value={submitData.password}
                  onBlur={() => validateOnBlur("password")}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\s/g, "");
                  }}
                  onChange={(e) => {
                    const submitData = {
                      ...this.state.submitData,
                      password: !e.target.value.trim() ? "" : e.target.value,
                    };
                    this.setState({ submitData: submitData });
                  }}
                />
                {!showPass ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                    onClick={() => {
                      this.setState({ showPass: !this.state.showPass });
                    }}
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                    onClick={() => {
                      this.setState({ showPass: !this.state.showPass });
                    }}
                  >
                    <path
                      d="M0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0z"
                      fill="none"
                    />
                    <path d="M12 6c3.79 0 7.17 2.13 8.82 5.5-.59 1.22-1.42 2.27-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l1.65 1.65C10.66 6.09 11.32 6 12 6zm-1.07 1.14L13 9.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C16.5 9.01 14.48 7 12 7c-.37 0-.72.05-1.07.14zM2.01 3.87l2.68 2.68C3.06 7.83 1.77 9.53 1 11.5 2.73 15.89 7 19 12 19c1.52 0 2.98-.29 4.32-.82l3.42 3.42 1.41-1.41L3.42 2.45 2.01 3.87zm7.5 7.5l2.61 2.61c-.04.01-.08.02-.12.02-1.38 0-2.5-1.12-2.5-2.5 0-.05.01-.08.01-.13zm-3.4-3.4l1.75 1.75c-.23.55-.36 1.15-.36 1.78 0 2.48 2.02 4.5 4.5 4.5.63 0 1.23-.13 1.77-.36l.98.98c-.88.24-1.8.38-2.75.38-3.79 0-7.17-2.13-8.82-5.5.7-1.43 1.72-2.61 2.93-3.53z" />
                  </svg>
                )}
              </div>
              {errorMessage.password && (
                <p className="error-text">{errorMessage.password}</p>
              )}
            </div>
          </div>

          <div className="mb-3 booking-form__row required">
            <div className="booking-form__label">
              <label className="form-label user-select-none">
                パスワードを認証する
              </label>
            </div>
            <div className="booking-form__control">
              <div className="user-booking-register__password-field">
                <input
                  className="form-control"
                  maxLength="16"
                  type={!showConfirmPass ? "password" : "text"}
                  placeholder="8文字以上で入力してください。"
                  value={submitData.confirm_password}
                  onBlur={() => validateOnBlur("confirm_password")}
                  onChange={(e) => {
                    const submitData = {
                      ...this.state.submitData,
                      confirm_password: !e.target.value.trim()
                        ? ""
                        : e.target.value,
                    };
                    this.setState({ submitData: submitData });
                  }}
                />
                {!showConfirmPass ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                    onClick={() => {
                      this.setState({
                        showConfirmPass: !this.state.showConfirmPass,
                      });
                    }}
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                    onClick={() => {
                      this.setState({
                        showConfirmPass: !this.state.showConfirmPass,
                      });
                    }}
                  >
                    <path
                      d="M0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0z"
                      fill="none"
                    />
                    <path d="M12 6c3.79 0 7.17 2.13 8.82 5.5-.59 1.22-1.42 2.27-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l1.65 1.65C10.66 6.09 11.32 6 12 6zm-1.07 1.14L13 9.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C16.5 9.01 14.48 7 12 7c-.37 0-.72.05-1.07.14zM2.01 3.87l2.68 2.68C3.06 7.83 1.77 9.53 1 11.5 2.73 15.89 7 19 12 19c1.52 0 2.98-.29 4.32-.82l3.42 3.42 1.41-1.41L3.42 2.45 2.01 3.87zm7.5 7.5l2.61 2.61c-.04.01-.08.02-.12.02-1.38 0-2.5-1.12-2.5-2.5 0-.05.01-.08.01-.13zm-3.4-3.4l1.75 1.75c-.23.55-.36 1.15-.36 1.78 0 2.48 2.02 4.5 4.5 4.5.63 0 1.23-.13 1.77-.36l.98.98c-.88.24-1.8.38-2.75.38-3.79 0-7.17-2.13-8.82-5.5.7-1.43 1.72-2.61 2.93-3.53z" />
                  </svg>
                )}
              </div>
              {errorMessage.confirm_password && (
                <p className="error-text">{errorMessage.confirm_password}</p>
              )}
            </div>
          </div>

          <div className="mb-3 booking-form__row required">
            <div className="booking-form__label">
              <label className="form-label user-select-none">
                姓（非公開）
              </label>
            </div>
            <div className="booking-form__control">
              <input
                className="form-control"
                maxLength="64"
                type="text"
                value={submitData.first_name}
                onBlur={() => validateOnBlur("first_name")}
                onChange={(e) => {
                  const submitData = {
                    ...this.state.submitData,
                    first_name: !e.target.value.trim() ? "" : e.target.value,
                  };
                  this.setState({ submitData: submitData });
                }}
              />
              {errorMessage.first_name && (
                <p className="error-text">{errorMessage.first_name}</p>
              )}
            </div>
          </div>

          <div className="mb-3 booking-form__row required">
            <div className="booking-form__label">
              <label className="form-label user-select-none">
                名（非公開）
              </label>
            </div>
            <div className="booking-form__control">
              <input
                className="form-control"
                maxLength="64"
                type="text"
                value={submitData.last_name}
                onBlur={() => validateOnBlur("last_name")}
                onChange={(e) => {
                  const submitData = {
                    ...this.state.submitData,
                    last_name: !e.target.value.trim() ? "" : e.target.value,
                  };
                  this.setState({ submitData: submitData });
                }}
              />
              {errorMessage.last_name && (
                <p className="error-text">{errorMessage.last_name}</p>
              )}
            </div>
          </div>

          <div className="mb-3 booking-form__row">
            <div className="booking-form__label">
              <label className="form-label user-select-none">生年月日</label>
            </div>
            <div className="booking-form__control">
              <TextField
                id="date"
                type="date"
                value={submitData.birthday}
                inputProps={{ max: moment().format("YYYY-MM-DD") }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const submitData = {
                    ...this.state.submitData,
                    birthday: e.target.value,
                  };
                  this.setState({ submitData: submitData });
                }}
              />
            </div>
          </div>

          <div className="mb-3 booking-form__row">
            <div className="booking-form__label">
              <label className="form-label user-select-none">電話番号</label>
            </div>
            <div className="booking-form__control">
              <input
                className="form-control"
                maxLength="20"
                type="text"
                inputMode="tel"
                value={submitData.phone_number}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                onChange={(e) => {
                  const submitData = {
                    ...this.state.submitData,
                    phone_number: !e.target.value.trim() ? "" : e.target.value,
                  };
                  this.setState({ submitData: submitData });
                }}
              />
              {errorMessage.phone_number && (
                <p className="error-text">{errorMessage.phone_number}</p>
              )}
            </div>
          </div>

          <div className="mb-3 booking-form__row">
            <div className="booking-form__label">
              <label className="form-label user-select-none">
                {shopDetail.city_name === "LGBTQ" ? "セクシュアリティ" : "性別"}
              </label>
            </div>
            <div className="booking-form__control">
              <p
                style={{ marginBottom: "10px" }}
                className="user-booking-register__txt-hint"
              >
                ※個人に合った情報受信に有効です
              </p>
              <select
                className="form-select"
                value={submitData.gender}
                onChange={(e) => {
                  const submitData = {
                    ...this.state.submitData,
                    gender: e.target.selectedOptions[0].value,
                  };
                  this.setState({ submitData: submitData });
                }}
              >
                {genderOptions.length &&
                  genderOptions.map((item) => (
                    <option
                      value={item.value}
                      label={item.label}
                      key={item.value}
                    >
                      {item.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="mb-3 booking-form__row">
            <div className="booking-form__label">
              <label className="form-label user-select-none">住所</label>
            </div>
            <div className="booking-form__control">
              <p className="user-booking-register__txt-hint">
                ※災害時には提供される情報が町ごとに変わるため町名までの入力をおすすめします。プッシュ通知にて情報が得られます（番地は必要ありません）
              </p>
              <div className="mb-3 booking-form__row child">
                <div className="booking-form__label">
                  <label className="form-label user-select-none">
                    都道府県（非公開）
                  </label>
                </div>
                <div className="booking-form__control">
                  <select
                    className="form-select"
                    value={submitData.prefecture}
                    onChange={async (e) => {
                      const submitData = {
                        ...this.state.submitData,
                        prefecture: e.target.selectedOptions[0].value,
                      };
                      await this.setState({ submitData: submitData });

                      fecthCities({
                        prefCode: e.target.selectedOptions[0].value,
                      }).then(({ data }) => {
                        const submitData = {
                          ...this.state.submitData,
                          city: data.result[0].cityCode,
                        };
                        this.setState({ listCity: data.result, submitData });
                      });
                    }}
                  >
                    {listPrefecture.map((item) => (
                      <option
                        value={item.prefCode}
                        label={item.prefName}
                        key={item.prefCode}
                      >
                        {item.prefName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mb-3 booking-form__row child">
                <div className="booking-form__label">
                  <label className="form-label user-select-none">
                    市区町村（非公開）
                  </label>
                </div>
                <div className="booking-form__control">
                  <select
                    className="form-select"
                    value={submitData.city}
                    onChange={async (e) => {
                      const submitData = {
                        ...this.state.submitData,
                        city: e.target.selectedOptions[0].value,
                      };
                      await this.setState({ submitData });
                    }}
                  >
                    {listCity.map((item) => (
                      <option
                        value={item.cityCode}
                        label={item.cityName}
                        key={item.cityCode}
                      >
                        {item.cityName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mb-3 booking-form__row child">
                <div className="booking-form__label">
                  <label className="form-label user-select-none">
                    町名（非公開)
                  </label>
                </div>
                <div className="booking-form__control">
                  <input
                    value={submitData.town}
                    className="form-control"
                    maxLength="200"
                    onChange={(e) => {
                      const submitData = {
                        ...this.state.submitData,
                        town: !e.target.value.trim() ? "" : e.target.value,
                      };
                      this.setState({ submitData: submitData });
                    }}
                  />
                </div>
              </div>
              <div className="mb-3 booking-form__row child">
                <div className="booking-form__label">
                  <label className="form-label user-select-none">
                    丁目番地号（非公開）
                  </label>
                </div>
                <div className="booking-form__control">
                  <input
                    value={submitData.street}
                    maxLength="200"
                    className="form-control"
                    onChange={(e) => {
                      const submitData = {
                        ...this.state.submitData,
                        street: !e.target.value.trim() ? "" : e.target.value,
                      };
                      this.setState({ submitData: submitData });
                    }}
                  />
                </div>
              </div>
              <div className="mb-3 booking-form__row child">
                <div className="booking-form__label">
                  <label className="form-label user-select-none">
                    マンション名部室番号等（非公開）
                  </label>
                </div>
                <div className="booking-form__control">
                  <input
                    className="form-control"
                    maxLength="200"
                    value={submitData.house_number}
                    onChange={(e) => {
                      const submitData = {
                        ...this.state.submitData,
                        house_number: !e.target.value.trim()
                          ? ""
                          : e.target.value,
                      };
                      this.setState({ submitData: submitData });
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="user-booking-register__confirm-box">
                <input
                  value={submitData.confirmed}
                  type="checkbox"
                  onChange={(e) => {
                    const submitData = {
                      ...this.state.submitData,
                      confirmed: e.target.checked,
                    };
                    validateOnBlur("confirmed");
                    this.setState({ submitData: submitData });
                  }}
                />
                <div>
                  ご
                  <Link to="/booking/term-of-service" target="_blank">
                    利用規約
                  </Link>
                  に同意する
                </div>
              </div>
              {errorMessage.confirmed && (
                <p className="error-text">{errorMessage.confirmed}</p>
              )}
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <MyButton
              myVariant="create"
              isLoading={isBtnDisabled || !isInitialLoadDone}
              onClick={register}
            >
              登録
            </MyButton>
          </div>
        </div>
        {isRegisterDone ? <SuccessModal {...this.props} /> : ""}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRegister);
