import React, { Component } from "react";
import baseContentLayout from "./../shared/base-content/index";
import "./styles.scss";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DetailDialog from "./detail";
import MyButton from "./../shared/buttons/primary";

const LIMIT = 20;

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detailDialogShow: false,
      isLoading: false,
      listBooking: [],
      bookingDetail: {},
      searchQuery: "",
      totalItems: "",
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.fetchBooking({ page: 1 });
  }

  searchBooking = () => {
    this.setState({ isLoading: true });
    this.fetchBooking({ page: 1 });
  };

  fetchBooking = ({ page }) => {
    window
      .axios({
        url: "booking/",
        method: "get",
        params: {
          page: page,
          limit: LIMIT,
          shop: window.store.getState().auth.user.id,
          search: this.state.searchQuery.trim(),
        },
      })
      .then((result) => {
        this.setState({
          listBooking: result.data.results,
          isLoading: false,
          totalItems: result.data.count,
        });
      });
  };

  onDetailClick = (item) => {
    this.setState({
      bookingDetail: item,
      detailDialogShow: true,
    });
  };

  handlePageClick = ({ selected }) => {
    this.fetchBooking({ page: +selected + 1 });
  };

  render() {
    const {
      searchQuery,
      isLoading,
      listBooking,
      detailDialogShow,
      totalItems,
      bookingDetail,
    } = this.state;
    const { fetchBooking, searchBooking, onDetailClick, handlePageClick } =
      this;
    return (
      <div className="history">
        <div className="history__search mb-4">
          <div className="form-group col-md-3 d-inline-block me-3 mb-2">
            <input
              type="text"
              value={searchQuery}
              className="form-control no-box-shadow d-inline-block"
              placeholder="ユーザ名"
              onChange={async (e) => {
                this.setState({ searchQuery: e.target.value });
                // if (!e.target.value) {
                //   fetchBooking();
                // }
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.setState({
                    isLoading: true,
                  });

                  fetchBooking({ page: 1 });
                }
              }}
            />
          </div>
          <MyButton myVariant="search" onClick={searchBooking}>
            検索
          </MyButton>
        </div>
        {!isLoading ? (
          <>
            <div className="history__table">
              <table className="table">
                <thead className="">
                  <tr>
                    <th scope="col" className="col-username">
                      ユーザ名
                    </th>
                    <th scope="col" className="col-service">
                      メニュー
                    </th>
                    <th scope="col" className="col-status">
                      ステータス
                    </th>
                    <th scope="col" className="col-time">
                      予約時間
                    </th>
                    <th scope="col" className="col-cancel-time">
                      キャンセル時間
                    </th>
                    <th scope="col" className="col-activity">
                      操作
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listBooking.length ? (
                    listBooking.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {window._.truncate(
                            item.username || item.user.full_name,
                            { length: 30, omission: "..." }
                          )}
                        </td>
                        <td>
                          {window._.truncate(
                            item.menu.map((i) => i.name).join(", "),
                            { length: 50, omission: "..." }
                          )}
                        </td>
                        <td>
                          {
                            [
                              {
                                value: "waiting",
                                label: "予約待ち",
                              },
                              {
                                value: "approved",
                                label: "予約済み",
                              },
                              {
                                value: "processing",
                                label: "進行中",
                              },
                              {
                                value: "done",
                                label: "完了",
                              },
                              {
                                value: "cancelled",
                                label: "予約キャンセル",
                              },
                            ].find((status) => status.value === item.status)
                              .label
                          }
                        </td>
                        <td>
                          {moment(item.start_time).format(
                            "YYYY年MM月DD日 hh:mm a"
                          )}
                          <br />
                          {moment(item.end_time).format(
                            "YYYY年MM月DD日 hh:mm a"
                          )}
                        </td>
                        <td>
                          {item.cancel_time
                            ? moment(item.cancel_time).format(
                                "YYYY年MM月DD日 hh:mm a"
                              )
                            : ""}
                        </td>
                        {
                          <td>
                            <MyButton
                              myVariant="detail"
                              onClick={() => onDetailClick(item)}
                            >
                              詳細
                            </MyButton>
                          </td>
                        }
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">予約履歴がありません</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {totalItems > 20 ? (
              <ReactPaginate
                previousLabel={
                  <span className="material-icons">chevron_left</span>
                }
                nextLabel={
                  <span className="material-icons">chevron_right</span>
                }
                disabledClassName={"navigation-disabled"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(totalItems / LIMIT)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"my-pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          <div className="loader-wrapper">
            <div className="loader">Loading...</div>
          </div>
        )}

        {detailDialogShow && (
          <DetailDialog
            isShow={detailDialogShow}
            onClose={() => {
              this.setState({
                bookingDetail: {},
                detailDialogShow: false,
              });
            }}
            data={bookingDetail}
          />
        )}
      </div>
    );
  }
}

const HistoryContent = baseContentLayout(Content);

class index extends Component {
  render() {
    return <HistoryContent name="予約履歴" />;
  }
}

export default index;
