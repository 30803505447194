import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import NotMatch from "./../controllers/notmatch";
import { default as loginRoute } from "./pages/login";
import bookingRoute from "./pages/booking";
import historyRoute from "./pages/history";
import settingRoute from "./pages/setting";
import p404Route from "./pages/404";
import userRoute from "./pages/user";

const routes = [
  ...p404Route,
  ...loginRoute,
  ...bookingRoute,
  ...historyRoute,
  ...settingRoute,
  ...userRoute,
];

export { routes };

export default connect((state) => ({
  auth: state.auth,
  setting: state.setting,
}))(({ auth, setting }) => {
  return (
    // <Router history={history}>
    <Switch>
      {routes
        .filter((route) => route.protected)
        .map((route, key) => (
          <Route
            key={key}
            exact={route.exact}
            path={route.path}
            render={(props) =>
              auth.token ? (
                setting.isLoadMenuDone && setting.isLoadStaffDone ? (
                  React.createElement(route.component, { ...props })
                ) : (
                  ""
                )
              ) : (
                <Redirect to="/404" />
              )
            }
          />
        ))}
      {routes
        .filter((route) => !route.protected)
        .map((route, key) => (
          <Route
            key={key}
            exact={route.exact}
            path={route.path}
            render={(props) =>
              React.createElement(route.component, { ...props })
            }
          />
        ))}
      <Route exact={true} path="/" render={() => <Redirect to="/booking" />} />
      <Route exact={true} path="/404" component={NotMatch} />
    </Switch>
    // </Router>
  );
});

export const reducer = (function () {
  let reducer = {};
  Array.prototype.forEach.call(routes, (route) => {
    if (route.reducer) {
      Object.assign(reducer, { [route.name]: route.reducer });
    }
  });
  return reducer;
})();

export const sagas = (function () {
  let sagas = [];
  Array.prototype.forEach.call(routes, (route) => {
    if (route.sagas) {
      sagas.push(route.sagas);
    }
  });

  return sagas;
})();
