import { call, put, all, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import * as api from "./api";
import * as types from "./types";

function* handleGetShopInfo(action) {
  try {
    const result = yield call(api.getToken, action.payload);
    // console.log(result);
    const user = result.data;
    const resultFullShopInfo = yield call(api.getFullShopInfo, user.id);
    yield put(actions.setUser(resultFullShopInfo.data));
  } catch (e) {
    // yield put(actions.loginError());
    throw e;
  }
}

function* appSaga() {
  yield all([takeLatest(types.GET_SHOP_INFO, handleGetShopInfo)]);
}

export default appSaga;
