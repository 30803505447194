const validateStr = {
  menuRequire: "メニュー名を入力してください。",
  detailRequire: "説明内容を入力してください。",
  priceRequire: "価格を入力してください。",
  durationRequire: "時間を入力してください。",
};

export const timeBlockMinute = [
  "30",
  "60",
  "90",
  "120",
  "150",
  "180",
  "210",
  "240",
  "270",
  "300",
  "330",
  "360",
  "390",
  "420",
];
export const timeBlockHour = [
  "0.5",
  "1",
  "1.5",
  "2",
  "2.5",
  "3",
  "3.5",
  "4",
  "4.5",
  "5",
  "5.5",
  "6",
  "6.5",
  "7",
];

export default validateStr;
