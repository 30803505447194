import React, { Component } from "react";
import Loading from "./../../shared/loading-box";
import request from "../../../core/api";
import "./index.scss";
import * as actions from "./../../../modules/user/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";

class Redirect extends Component {
  async componentDidMount() {
    const { dispatchActions } = this.props;
    const query = this.props.location.search;
    const refreshToken = new URLSearchParams(query).get("token");

    // await this.fetchUserDetail(token).then((result) => {
    //   const userData = {
    //     access: token,
    //     user: result.data,
    //   };

    //   dispatchActions.setUserToken(userData);
    // });
    this.refresh(refreshToken).then((result) => {
      const { access, refresh } = result.data;
      dispatchActions.setUserToken(access);
      dispatchActions.setUserRefreshToken(refresh);

      this.fetchUserDetail(access).then((info) => {
        dispatchActions.setUserInfo(info.data);
      });
    });
  }

  refresh = (refreshToken) => {
    return request({
      url: `token/refresh/`,
      method: "post",
      data: {
        refresh: refreshToken,
      },
    });
  };

  componentDidUpdate() {
    const shop = new URLSearchParams(this.props.location.search).get("shop");

    const { userInfo, userToken, userRefreshToken } = this.props.user;
    if (!isEmpty(userInfo) && userToken && userRefreshToken) {
      this.props.history.replace(`/${shop}/booking`);
    }
  }

  fetchUserDetail = (token) => {
    return request({
      url: "user/profile/",
      method: "get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  render() {
    return (
      <div className="redirect-view">
        <Loading />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Redirect);
