import React from "react";
import "./index.scss";
import moment from "moment";
import Dialog from "../../../shared/dialog";
import "moment/locale/ja";
import MyButton from "./../../../shared/buttons/primary";

moment.locale("ja");

class AlertDialog extends React.Component {
  render() {
    const { onClose, currDayWorkingHours } = this.props;
    return (
      <Dialog
        id="create-booking-alert"
        className={"create-booking-alert"}
        title={"!!!"}
        isShow={true}
        onCloseRequest={() => onClose({})}
        closeOnOverlayClick={false}
      >
        <div className="create-booking-alert__content">
          <p>予約が作成できません。</p>
          <p>今日の営業時間は以下となります:</p>
          <ul className="create-booking-alert__working-time">
            {currDayWorkingHours.map((item, index) => (
              <li key={index}>
                {moment(item.start).format("HH:mm")} -{" "}
                {moment(item.end).format("HH:mm") === "23:59"
                  ? "24:00"
                  : moment(item.end).format("HH:mm")}
              </li>
            ))}
          </ul>
        </div>

        <div className="create-booking-alert__btns">
          <MyButton
            className="create-booking-alert__alert-btn"
            myVariant="create"
            onClick={() => onClose()}
          >
            閉じる
          </MyButton>
        </div>
      </Dialog>
    );
  }
}

export default AlertDialog;
