const constant = {
  MAX_SIZE_IMAGE: 15728640,
  ROUTE_TYPE_USER: 0,
  ROUTE_TYPE_ADUSER: 1,
  bookingStatus: {
    waiting: "waiting",
    approved: "approved",
    processing: "processing",
    done: "done",
    cancelled: "cancelled",
  },
  discountType: {
    PERCENTAGE: "Percentage",
    FIXED: "Fixed",
  },
};

export default constant;
