import request from "./../../core/api";

export const getToken = (id) => {
  return request({
    url: `/shop/get_by_id/?old_id=${id}`,
    method: "get",
  });
};

export const getFullShopInfo = (id) => {
  return request({
    url: `/shop/${id}/`,
    method: "get",
  });
};
