import Immutable from "seamless-immutable";
import * as types from "./types";

const initialState = Immutable({
  user: !!sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : {},
  token: !!sessionStorage.getItem("access-token")
    ? sessionStorage.getItem("access-token")
    : "",
  refreshToken: !!sessionStorage.getItem("refresh-token")
    ? sessionStorage.getItem("refresh-token")
    : "",
});

const setToken = (action, state) => {
  const { payload } = action;
  sessionStorage.setItem("access-token", payload);
  return state.merge({ token: payload });
};

const setRefreshToken = (action, state) => {
  const { payload } = action;
  sessionStorage.setItem("refresh-token", payload);
  return state.merge({ refreshToken: payload });
};

const setUser = (action, state) => {
  const { payload } = action;
  sessionStorage.setItem("user", JSON.stringify(payload));
  return state.merge({ user: payload });
};

const dispatch = {
  [types.SET_TOKEN]: setToken,
  [types.SET_REFRESH_TOKEN]: setRefreshToken,
  [types.SET_USER]: setUser,
};

const reducer = (state, action) => {
  if (typeof state === "undefined") {
    return initialState;
  }
  return typeof dispatch[action.type] === "function"
    ? dispatch[action.type](action, state)
    : state;
};

export default reducer;
