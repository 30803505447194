import React, { Component } from "react";
import Dialog from "../../../shared/dialog";
import "./index.scss";

class SuccessModal extends Component {
  backToBooking = () => {
    this.props.history.replace(`/${this.props.match.params.id}/booking`);
  };

  render() {
    return (
      <div>
        <Dialog
          className={"register-success-dialog"}
          title={"成功"}
          isShow={true}
          closeByEscKey={false}
          showCloseButton={false}
          closeOnOverlayClick={false}
        >
          <h2 className="register-success-dialog__text">
            アカウントを登録しました。
          </h2>

          <div style={{ textAlign: "center" }}>
            <button
              className="register-success-dialog__button"
              onClick={this.backToBooking}
            >
              Bookingへ戻る
            </button>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default SuccessModal;
