export const listCity = [
  {
    city: "nishinomiya",
    prefecture: "兵庫県",
    cityName: "西宮市",
    town: "",
  },
  {
    city: "takatsuki",
    prefecture: "大阪府",
    cityName: "高槻市",
    town: "",
  },
  {
    city: "akashi",
    prefecture: "兵庫県",
    cityName: "明石市",
    town: "",
  },
  {
    city: "toyonaka",
    prefecture: "大阪府",
    cityName: "豊中市",
    town: "",
  },
  {
    city: "kusatsu",
    prefecture: "滋賀県",
    cityName: "草津市",
    town: "",
  },
  {
    city: "settsu",
    prefecture: "大阪府",
    cityName: "摂津市",
    town: "",
  },
  {
    city: "fujisawa",
    prefecture: "神奈川県",
    cityName: "藤沢市",
    town: "",
  },
  {
    city: "amagasaki",
    prefecture: "兵庫県",
    cityName: "尼崎市",
    town: "",
  },
  {
    city: "shinjuku",
    prefecture: "東京都",
    cityName: "新宿区",
    town: "",
  },
  {
    city: "sannomiya",
    prefecture: "兵庫県",
    cityName: "神戸市",
    town: "中央区",
  },
  {
    city: "fukushimaku",
    prefecture: "大阪府",
    cityName: "大阪市",
    town: "福島区",
  },
  {
    city: "moriguchi",
    prefecture: "大阪府",
    cityName: "守口市",
    town: "",
  },
  {
    city: "tsukiji-tsukishima-kachidoki",
    prefecture: "東京都",
    cityName: "中央区",
    town: "",
  },
  {
    city: "honmachi-yodoyabashi",
    prefecture: "大阪府",
    cityName: "大阪市中央区",
    town: "",
  },
  {
    city: "shonan",
    prefecture: "神奈川県",
    cityName: "藤沢市",
    town: "",
  },
  {
    city: "nagareyama",
    prefecture: "千葉県",
    cityName: "流山市",
    town: "",
  },
  {
    city: "neyagawa",
    prefecture: "大阪府",
    cityName: "寝屋川市",
    town: "",
  },
  {
    city: "takaishi",
    prefecture: "大阪府",
    cityName: "高石市",
    town: "",
  },
  {
    city: "gifu",
    prefecture: "岐阜県",
    cityName: "岐阜市",
    town: "",
  },
  {
    city: "suita",
    prefecture: "大阪府",
    cityName: "吹田市",
    town: "",
  },
  {
    city: "higashiosaka",
    prefecture: "大阪府",
    cityName: "東大阪市",
    town: "",
  },
  {
    city: "ikeda",
    prefecture: "大阪府",
    cityName: "池田市",
    town: "",
  },
  {
    city: "kitashinchi",
    prefecture: "大阪府",
    cityName: "大阪市北区",
    town: "",
  },
  {
    city: "osakashi-nishiku",
    prefecture: "大阪府",
    cityName: "大阪市西区",
    town: "",
  },
  {
    city: "himeji",
    prefecture: "兵庫県",
    cityName: "姫路市",
    town: "",
  },
  {
    city: "minoo",
    prefecture: "大阪府",
    cityName: "箕面市",
    town: "",
  },
  {
    city: "kawachinagano",
    prefecture: "大阪府",
    cityName: "河内長野市",
    town: "",
  },
  {
    city: "chiba",
    prefecture: "千葉県",
    cityName: "千葉市",
    town: "",
  },
  {
    city: "kishiwada",
    prefecture: "大阪府",
    cityName: "岸和田市",
    town: "",
  },
  {
    city: "kobe",
    prefecture: "兵庫県",
    cityName: "神戸市",
    town: "",
  },
  {
    city: "nara-ikoma",
    prefecture: "奈良県",
    cityName: "奈良市",
    town: "",
  },
  {
    city: "wakayama",
    prefecture: "和歌山県",
    cityName: "和歌山市",
    town: "",
  },
  {
    city: "yao",
    prefecture: "大阪府",
    cityName: "八尾市",
    town: "",
  },
  {
    city: "naha",
    prefecture: "沖縄県",
    cityName: "那覇市",
    town: "",
  },
  {
    city: "daito-shijonawate",
    prefecture: "大阪府",
    cityName: "大東市",
    town: "",
  },
  {
    city: "tsu",
    prefecture: "三重県",
    cityName: "津市",
    town: "",
  },
  {
    city: "fushimiku",
    prefecture: "京都府",
    cityName: "京都市伏見区",
    town: "",
  },
  {
    city: "osakaminami",
    prefecture: "大阪府",
    cityName: "大阪市中央区",
    town: "",
  },
  {
    city: "sakai",
    prefecture: "大阪府",
    cityName: "堺市",
    town: "",
  },
  {
    city: "okayama",
    prefecture: "岡山県",
    cityName: "岡山市",
    town: "",
  },
  {
    city: "hamamatsu",
    prefecture: "静岡県",
    cityName: "浜松市",
    town: "",
  },
  {
    city: "kobenishiku",
    prefecture: "兵庫県",
    cityName: "神戸市西区",
    town: "",
  },
  {
    city: "kobeshihigashinadaku",
    prefecture: "兵庫県",
    cityName: "神戸市東灘区",
    town: "",
  },
  {
    city: "hirakata",
    prefecture: "大阪府",
    cityName: "枚方市",
    town: "",
  },
  {
    city: "matsumoto",
    prefecture: "長野県",
    cityName: "松本市",
    town: "",
  },
  {
    city: "shibushi",
    prefecture: "鹿児島県",
    cityName: "志布志市",
    town: "",
  },
  {
    city: "ikebukuro",
    prefecture: "東京都",
    cityName: "豊島区",
    town: "",
  },
  {
    city: "anilla-town",
    prefecture: "神奈川県",
    cityName: "横浜市",
  },
  {
    city: "kameoka-nantan",
    prefecture: "京都府",
    cityName: "亀岡市",
  },
  {
    city: "uji",
    prefecture: "京都府",
    cityName: "宇治市",
  },
  {
    city: "shirahama",
    prefecture: "和歌山県",
    cityName: "白浜町",
  },
  {
    city: "kitakyushu",
    prefecture: "福岡県",
    cityName: "北九州市",
  },
  {
    city: "LGBTQ",
    prefecture: "東京都",
    cityName: "中央区",
  },
  {
    city: 'nakasu-tenjin-hakata',
    prefecture: '福岡県',
    cityName: '福岡市博多区'
  },
  {
    city: 'miyakojima',
    prefecture: '沖縄県',
    cityName: '宮古島市'
  },
  {
    city: 'kakogawa',
    prefecture: '兵庫県',
    cityName: '加古川市'
  },
  {
    city: 'nakanoku',
    prefecture: '東京都',
    cityName: '中野区'
  },
  {
    city: 'saiin',
    prefecture: '京都府',
    cityName: '京都市右京区'
  },
  {
    city: 'ichinomiya',
    prefecture: '愛知県',
    cityName: '一宮市'
  },
  {
    city: 'takasago',
    prefecture: '兵庫県',
    cityName: '高砂市'
  },
  {
    city: 'funabashi',
    prefecture: '千葉県',
    cityName: '船橋市'
  },
  {
    city: 'matsubara',
    prefecture: '大阪府',
    cityName: '松原市'
  },
  {
    city: 'ibaraki',
    prefecture: '大阪府',
    cityName: '茨木市'
  },
  {
    city: 'akita',
    prefecture: '秋田県',
    cityName: '秋田市'
  },
  {
    city: 'tokushima',
    prefecture: '徳島県',
    cityName: '徳島市'
  }
];
