import React from "react";
import "./index.scss";
import * as actions from "./../../../modules/booking/actions";
import * as settingActions from "./../../../modules/setting/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LightCalendar from "../../shared/light-calendar";

const CHILD_ROUTES = [
  "/setting/common",
  "/setting/staff",
  "/setting/menu",
  "/setting/discount",
];

class NavigationPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chosenDate: new Date(),
    };
  }

  componentDidMount() {
    const { currentRoute } = this.props;
    this.transformArrow();
    if (CHILD_ROUTES.includes(currentRoute.path)) {
      this.showCollapse();
    }

    this.loadInitialData();

    this.unlistener = this.props.history.listen(() => {
      this.loadInitialData();
    });
  }

  loadInitialData() {
    const { dispatchActions } = this.props;
    dispatchActions.getListMenu(this.props.auth.user.id);
    dispatchActions.getListStaff(this.props.auth.user.id);
  }

  showCollapse() {
    const myCollapse = document.getElementById("collapse-controller");
    myCollapse.click();
  }

  transformArrow() {
    const collapseAreaNode = document.getElementById("collapse-area");
    const arrowNode = document.getElementById("chevron-icon");

    collapseAreaNode.addEventListener("show.bs.collapse", () => {
      arrowNode.classList.remove("rotate-down");
      arrowNode.classList.add("rotate-right");
    });

    collapseAreaNode.addEventListener("hide.bs.collapse", () => {
      arrowNode.classList.remove("rotate-right");
      arrowNode.classList.add("rotate-down");
    });
  }

  changePath = (path) => {
    if (window.innerWidth <= 900) {
      const checkbox = document.getElementById("check");
      checkbox.checked = !checkbox.checked;
    }
    this.props.history.push(path);
  };

  onSidebarBlur = () => {
    if (window.innerWidth <= 900) {
      const checkbox = document.getElementById("check");
      if (checkbox.checked) {
        checkbox.checked = false;
      }
    }
  };

  onChangeDate = (date) => {
    this.props.dispatchActions.setSimpleCalendarChosenDate(date);
    const checkbox = document.getElementById("check");
    checkbox.checked = false;
  };

  render() {
    const { currentRoute } = this.props;
    const { onChangeDate, onSidebarBlur } = this;
    return (
      <div tabIndex="1" onBlur={onSidebarBlur}>
        <input
          className="navigation-panel__toggle-button"
          type="checkbox"
          id="check"
          style={{ display: "none" }}
        />
        <label
          className="navigation-panel__toggle-button material-icons"
          htmlFor="check"
        >
          menu
        </label>
        <div
          className="navigation-panel__overlay"
          onClick={onSidebarBlur}
        ></div>
        <div
          id="navigation-panel"
          className={[
            "navigation-panel",
            this.props.show ? "navigation-show" : "",
          ].join(" ")}
        >
          <h2 className="navigation-panel__shop-name">{this.props.title}</h2>
          <ul className="navigation-panel__options options">
            <li
              className="options__item schedule"
              onClick={() => this.changePath("/booking")}
            >
              <span
                role="button"
                // href="/booking"
                className={
                  currentRoute.path === "/booking" ? "navigation-active" : ""
                }
              >
                <i className="fas fa-calendar-alt me-2"></i>
                <p className="d-inline-block">カレンダー</p>
              </span>
            </li>
            <li className="options__item position-relative">
              <span
                id="collapse-controller"
                className="d-block"
                data-bs-toggle="collapse"
                href="#collapse-area"
                role="button"
                aria-expanded="false"
                aria-controls="collapse-area"
              >
                <i className="fas fa-cogs me-2"></i>設定
                <i
                  id="chevron-icon"
                  className="fas fa-chevron-down chevron-icon"
                ></i>
              </span>
              <ul className="collapse" id="collapse-area">
                <li
                  role="button"
                  onClick={() => this.changePath("/setting/staff")}
                  className="navigation-panel__staff"
                >
                  <span
                    className={
                      currentRoute.path === "/setting/staff"
                        ? "navigation-active"
                        : ""
                    }
                  >
                    <i className="fas fa-cog me-2"></i>
                    <span>
                      {window.store.getState().auth.user.section_name_1}
                    </span>
                  </span>
                </li>
                <li
                  role="button"
                  onClick={() => this.changePath("/setting/menu")}
                >
                  <span
                    className={
                      currentRoute.path === "/setting/menu"
                        ? "navigation-active"
                        : ""
                    }
                  >
                    <i className="fas fa-cog me-2"></i>メニュー
                  </span>
                </li>
                <li
                  role="button"
                  onClick={() => this.changePath("/setting/discount")}
                >
                  <span
                    className={
                      currentRoute.path === "/setting/discount"
                        ? "navigation-active"
                        : ""
                    }
                  >
                    <i className="fas fa-cog me-2"></i>割引
                  </span>
                </li>
                <li
                  role="button"
                  onClick={() => this.changePath("/setting/common")}
                >
                  <span
                    className={
                      currentRoute.path === "/setting/common"
                        ? "navigation-active"
                        : ""
                    }
                  >
                    <i className="fas fa-cog me-2"></i>共通
                  </span>
                </li>
              </ul>
            </li>
            <li
              className="options__item"
              onClick={() => this.changePath("/history")}
            >
              <span
                role="button"
                // href="/history"
                className={
                  currentRoute.path === "/history" ? "navigation-active" : ""
                }
              >
                <i className="fas fa-history me-2"></i>
                <span>履歴</span>
              </span>
            </li>
          </ul>
          {this.props.history.location.pathname === "/booking" &&
          this.props.setting.listMenu.length &&
          this.props.setting.listStaff.length ? (
            <div className="navigation-panel__calendar">
              <LightCalendar
                chosenDate={
                  this.props.booking.simpleCalendarChosenDate || new Date()
                }
                disableNotCurrentMonth={false}
                onClick={onChangeDate}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    booking: state.booking,
    setting: state.setting,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchActions: bindActionCreators(
    { ...actions, ...settingActions },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationPanel);
