import reducer from "./../../modules/auth/reducer";
import sagas from "./../../modules/auth/sagas";
import Login from "./../../controllers/login";
import constant from "./../../core/constant";

const routes = [
  {
    name: "auth",
    path: "/redirect/:id",
    component: Login,
    exact: true,
    reducer,
    sagas,
    type: constant.ROUTE_TYPE_ADUSER,
  },
];

export default routes;
