import React, { Component } from "react";
import Modal from "./../../../shared/dialog";
const removeDialog = {
  menu: "menu",
  staff: "staff",
  time: "time",
};

class confirmRemoveModal extends Component {
  remove = (needConfirm) => {
    const { type, dispatchActions, onClose } = this.props;
    dispatchActions.setConfirmWhenRemove(needConfirm);

    if (type === removeDialog.menu) {
      this.removeMenu();
    }

    if (type === removeDialog.time) {
      this.removeTime();
    }

    if (type === removeDialog.staff) {
      this.removeStaff();
    }

    onClose();
  };

  removeStaff = () => {
    const userBookingData = {
      ...this.props.user.userBookingData,
      chosenStaff: "",
    };
    this.props.dispatchActions.setUserBookingData({
      key: this.props.user.shopDetail.id,
      value: userBookingData,
    });
  };

  removeTime = () => {
    const userBookingData = {
      ...this.props.user.userBookingData,
      chosenTime: "",
      isUserChosenTime: false,
    };
    this.props.dispatchActions.setUserBookingData({
      key: this.props.user.shopDetail.id,
      value: userBookingData,
    });
  };

  removeMenu = () => {
    const listChosen = this.props.user.userBookingData.chosenMenu.split(",");
    const index = listChosen.findIndex((item) => +item === this.props.data.id);
    listChosen.splice(index, 1);

    const totalMoney = listChosen
      .map((chosen) =>
        this.props.user.shopMenus.find((menu) => +chosen === menu.id)
      )
      .reduce((acc, curr) => {
        return (acc += Number(curr.price));
      }, 0);

    const totalDuration = listChosen
      .map((chosen) =>
        this.props.user.shopMenus.find((menu) => +chosen === menu.id)
      )
      .reduce((acc, curr) => {
        return (acc += Number(curr.duration));
      }, 0);

    const totalDiscount = listChosen
      .map((chosen) =>
        this.props.user.shopMenus.find((menu) => +chosen === menu.id)
      )
      .reduce((acc, curr) => {
        const discount = this.props.user.shopDiscounts.find(
          (item) => curr.discount === item.id
        );

        let amount = acc;

        if (discount) {
          if (discount.type === "Percentage") {
            amount += (+discount.value * +curr.price) / 100;
          } else {
            amount += +discount.value;
          }
        }
        return amount;
      }, 0);

    const userBookingData = {
      ...this.props.user.userBookingData,
      total: totalMoney,
      duration: totalDuration,
      discount: totalDiscount,
      chosenMenu: listChosen.join(","),
    };

    this.props.dispatchActions.setUserBookingData({
      key: this.props.user.shopDetail.id,
      value: userBookingData,
    });
  };

  render() {
    const { remove } = this;
    return (
      <Modal
        id="remove-confirm-modal"
        className={"remove-confirm-modal"}
        title={"選択解除しますが、よろしいですか？"}
        showCloseButton={false}
        onCloseRequest={() => this.props.onClose()}
        closeOnOverlayClick={true}
        isShow={true}
      >
        <button
          className="remove-confirm-modal__button remove-confirm-modal__button--remove"
          type="button"
          onClick={() => remove(true)}
        >
          削除する
        </button>
        <button
          className="remove-confirm-modal__button remove-confirm-modal__button--no-need-confirm"
          type="button"
          onClick={() => remove(false)}
        >
          削除して再度確認しない
        </button>
        <button
          className="remove-confirm-modal__button remove-confirm-modal__button--close"
          type="button"
          onClick={() => this.props.onClose()}
        >
          削除しない
        </button>
      </Modal>
    );
  }
}

export default confirmRemoveModal;
