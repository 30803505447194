import React from "react";
import "./index.scss";
import moment from "moment";
import Dialog from "../../../shared/dialog";
import "moment/locale/ja";
import schema from "../validate";
import cloneDeep from "lodash/cloneDeep";
import intersection from "lodash/intersection";
import difference from "lodash/difference";
import MyButton from "./../../../shared/buttons/primary";

moment.locale("ja");

class CreateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: 0,
      isBtnDisabled: false,
      errorMessages: [],
      bookingData: {
        username: "",
        phone_number: "",
        start_time: "",
        end_time: "",
        note: "",
        staff: "",
        shop: "",
        menu: [],
        email: "",
      },
      listFilteredStaff: [],
    };
  }

  async componentDidMount() {
    if (window.store.getState().booking.isMobile) {
      const bookingDialogNode = document.querySelector(
        "#create-booking-dialog"
      );
      bookingDialogNode.addEventListener("scroll", () => {
        document.activeElement.blur();
      });
    }

    await this.setState((prevState) => {
      return {
        ...prevState,
        bookingData: {
          ...prevState.bookingData,
          shop: this.props.data.shop,
          start_time: moment(
            this.props.data.start_time,
            moment.ISO_8601
          ).format(),
          end_time: moment(this.props.data.end_time, moment.ISO_8601).format(),
          staff: this.props.data.staff,
          status: this.props.data.status,
        },
        listFilteredStaff: this.props.data.listStaff,
      };
    });

    this.setInitialCheckMenu();
    this.fetShopDetail();
  }

  setInitialCheckMenu = async () => {
    const { listStaff, staff: staffId } = this.props.data;

    const initialMenus = listStaff.find((s) => s.id === staffId).menus;
    const initialMenuIds = initialMenus.map((i) => i.id);

    for await (const id of initialMenuIds) {
      this.onCheckboxSelect({ target: { value: id.toString() } });
    }
  };

  fetShopDetail = () => {
    window
      .axios({
        url: `shop/${this.props.auth.user.id}/`,
        method: "get",
      })
      .then((result) => {
        this.setState((prev) => ({
          bookingData: {
            ...prev.bookingData,
            status: result.data.booking_confirm ? "waiting" : "approved",
          },
        }));
      });
  };

  createBooking = async () => {
    await this.setState({ isBtnDisabled: true });

    const { bookingData } = this.state;

    const validate = schema.validate(bookingData);

    const validateLabels = [
      "username",
      "email",
      "menu",
      "phone_number",
      "staff",
    ];

    let errors;
    errors = validate.error.details.filter((item) =>
      validateLabels.includes(item.context.label)
    );

    errors = errors.map((item) => {
      return {
        name: item.context.label,
        message: item.message,
      };
    });

    if (errors.length) {
      this.setState((prev) => ({
        ...prev,
        errorMessages: errors,
      }));

      this.setState({ isBtnDisabled: false });
      return;
    }

    const dataSubmit = cloneDeep(bookingData);

    dataSubmit.start_time = moment(dataSubmit.start_time).format(
      "YYYY-MM-DD HH:mm"
    );
    dataSubmit.end_time = moment(dataSubmit.end_time).format(
      "YYYY-MM-DD HH:mm"
    );

    const result = await window.axios({
      url: `shop/${window.store.getState().auth.user.id}/book/`,
      method: "post",
      data: dataSubmit,
    });
    this.setState({ isBtnDisabled: false });
    this.props.onClose(result.data);
  };

  onCheckboxSelect = async (e) => {
    const { listStaff } = this.props.data;

    const { value: selectedValue } = e.target;
    const selectedMenu = cloneDeep(this.state.bookingData.menu);

    const indexOfSelectedValue = selectedMenu.indexOf(selectedValue);
    if (indexOfSelectedValue !== -1) {
      selectedMenu.splice(indexOfSelectedValue, 1);
    } else {
      selectedMenu.push(selectedValue);
    }

    let listFilteredStaff;
    let staffsCanDoAllMenu = [];
    if (selectedMenu.length) {
      staffsCanDoAllMenu = cloneDeep(listStaff).filter((staff) => {
        let hasAllMenu = true;

        const menuIds = cloneDeep(staff.menus).map((item) =>
          item.id.toString()
        );

        const diff = difference(selectedMenu, menuIds);

        if (diff.length) {
          hasAllMenu = false;
        }

        if (hasAllMenu) return staff;

        return false;
      });
      listFilteredStaff = cloneDeep(listStaff).filter((staff) => {
        const menuIds = cloneDeep(staff.menus).map((item) =>
          item.id.toString()
        );
        let intersectionMenus = intersection(menuIds, selectedMenu);
        if (intersectionMenus.length) {
          return staff;
        } else {
          return false;
        }
      });
    } else {
      listFilteredStaff = listStaff;
    }

    await this.setState((prev) => ({
      ...prev,
      listFilteredStaff: listFilteredStaff,
      bookingData: {
        ...prev.bookingData,
        staff: staffsCanDoAllMenu.find((s) => s.id === +prev.bookingData.staff)
          ? prev.bookingData.staff
          : "",
      },
    }));

    let error;
    const {
      error: { details },
    } = schema.validate({
      menu: selectedMenu,
    });

    error = details.find((item) => item.context.label === "menu");
    if (!error) {
      const index = this.state.errorMessages.findIndex(
        (item) => item.name === "menu"
      );

      if (index !== -1) {
        const newErrMsgs = cloneDeep(this.state.errorMessages);
        newErrMsgs.splice(index, 1);

        this.setState((prev) => ({
          ...prev,
          errorMessages: newErrMsgs,
        }));
      }
    } else {
      const newErrMsgs = cloneDeep(this.state.errorMessages);
      newErrMsgs.push({
        name: "menu",
        message: error.message,
      });
      this.setState((prev) => ({
        ...prev,
        errorMessages: newErrMsgs,
      }));
    }

    const selectedMenuObj = cloneDeep(selectedMenu).map((item) => {
      return window.store
        .getState()
        .setting.listMenu.find((i) => i.id === +item);
    });

    const duration = selectedMenuObj.reduce((accumulator, currentValue) => {
      return accumulator + +currentValue.duration;
    }, 0);

    this.setState((prev) => ({
      ...prev,
      duration,
    }));

    let endTime = moment(this.state.bookingData.start_time)
      .add(duration, "minutes")
      .format();

    if (moment(endTime).isAfter(this.state.bookingData.start_time, "day")) {
      endTime = moment(
        moment(this.state.bookingData.start_time).format("YYYY-MM-DD")
      ).set({
        hour: "23",
        minutes: "59",
        second: "59",
      });
    }

    await this.setState((prevState) => ({
      ...prevState,
      bookingData: {
        ...prevState.bookingData,
        menu: selectedMenu,
        end_time: endTime,
      },
    }));
  };

  validateOnBlur = (e) => {
    let errorList;
    const {
      error: { details },
    } = schema.validate({
      [e.target.name]: e.target.value,
    });

    errorList = details;

    let errorMsgState = this.state.errorMessages;
    const error = errorList.find(
      (item) => item.context.label === e.target.name
    );

    if (!error) {
      const errorIdx = errorMsgState.findIndex(
        (error) => error.name === e.target.name
      );
      if (errorIdx !== -1) {
        errorMsgState.splice(errorIdx, 1);
      }
    } else {
      const item = errorMsgState.find(
        (item) => item.name === error.context.label
      );

      if (!item) {
        errorMsgState.push({
          name: e.target.name,
          message: error.message,
        });
      } else {
        errorMsgState = errorMsgState.map((item) => {
          if (item.name === error.context.label) {
            return {
              name: e.target.name,
              message: error.message,
            };
          }

          return item;
        });
      }
    }

    this.setState((prev) => ({
      ...prev,
      errorMessages: errorMsgState,
    }));
  };

  disabledEndTime = (date) => {
    const startTime = moment(this.state.bookingData.start_time).format(
      "YYYY-MM-DD"
    );
    const endTime = moment(date).format("YYYY-MM-DD");
    return moment(endTime).isBefore(moment(startTime));
  };

  handleStartTimeChange = (date) => {
    this.setState((prevState) => ({
      ...prevState,
      bookingData: {
        ...prevState.bookingData,
        start_time: moment(date, moment.ISO_8601).format(),
        end_time: moment(date).isSameOrAfter(
          moment(prevState.bookingData.end_time)
        )
          ? moment(date).add(this.state.duration, "minutes").format()
          : prevState.bookingData.end_time,
      },
    }));
  };

  handleEndTimeChange = (date) => {
    this.setState((prevState) => ({
      ...prevState,
      bookingData: {
        ...prevState.bookingData,
        end_time: moment(date, moment.ISO_8601).format(),
        start_time: moment(date).isSameOrBefore(
          moment(prevState.bookingData.start_time)
        )
          ? moment(date).add(-this.state.duration, "minutes").format()
          : prevState.bookingData.start_time,
        // start_time: moment(date).add(-this.state.duration, "minutes").format(),
      },
    }));
  };

  changeStartTime = (e) => {
    const { bookingData } = this.state;
    const startTime = moment(bookingData.start_time)
      .set({
        hour: e.target.value.split(":")[0],
        minutes: e.target.value.split(":")[1],
      })
      .format();

    let endTime = moment(startTime)
      .add(this.state.duration, "minutes")
      .format();

    if (moment(endTime).isAfter(startTime, "day")) {
      endTime = moment(moment(startTime).format("YYYY-MM-DD")).set({
        hour: "23",
        minutes: "59",
        second: "59",
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      bookingData: {
        ...prevState.bookingData,
        start_time: startTime,
        end_time: endTime,
      },
    }));
  };

  onSelectStaff = async (e) => {
    const { value } = e.target;

    if (value) {
      const {
        bookingData: { menu },
      } = this.state;
      const { listStaff } = this.props.data;
      const initialMenus = listStaff.find((s) => s.id === +value).menus;
      let initialMenuIds = cloneDeep(initialMenus).map((i) => i.id);

      if (!menu.length) {
        for await (const id of initialMenuIds) {
          this.onCheckboxSelect({ target: { value: id.toString() } });
        }
      } else {
        const initialMenuIdsMap = cloneDeep(initialMenuIds).map((i) =>
          i.toString()
        );
        const duplicate = intersection(initialMenuIdsMap, menu);

        for await (const item of menu) {
          if (!duplicate.includes(item)) {
            this.onCheckboxSelect({ target: { value: item.toString() } });
          }
        }
      }
    }

    this.setState((prev) => {
      return {
        ...prev,
        bookingData: {
          ...prev.bookingData,
          staff: value,
        },
      };
    });
  };

  render() {
    const { isShow, onClose } = this.props;
    const { errorMessages, listFilteredStaff, bookingData } = this.state;
    const { validateOnBlur, changeStartTime, onSelectStaff } = this;
    return (
      isShow && (
        <Dialog
          id="create-booking-dialog"
          className={"booking-dialog"}
          title={"予約作成"}
          isShow={isShow}
          onCloseRequest={() => onClose({})}
          closeOnOverlayClick={false}
        >
          <div className="mb-3 booking-form__row required">
            <div className="booking-form__label">
              <i className="fas fa-user me-2" />
              <label className="form-label user-select-none">ユーザ名</label>
            </div>
            <div className="booking-form__control">
              <input
                className="form-control"
                id="customerName"
                autoFocus
                maxLength="64"
                placeholder="ユーザ名"
                value={this.state.bookingData.username}
                name="username"
                onBlur={validateOnBlur}
                onChange={(e) =>
                  this.setState((prev) => {
                    return {
                      ...prev,
                      bookingData: {
                        ...prev.bookingData,
                        username: !e.target.value.trim() ? "" : e.target.value,
                      },
                    };
                  })
                }
              />
              {errorMessages.find((item) => item.name === "username") && (
                <p className="error-text">
                  {
                    errorMessages.find((item) => item.name === "username")
                      .message
                  }
                </p>
              )}
            </div>
          </div>
          <div className="mb-3 booking-form__row required">
            <div className="booking-form__label">
              <i className="fas fa-mobile-alt me-2" />
              <label className="form-label user-select-none">電話番号</label>
            </div>
            <div className="booking-form__control">
              <input
                className="form-control"
                placeholder="電話番号"
                maxLength="20"
                inputMode="tel"
                value={this.state.bookingData.phone_number}
                name="phone_number"
                onBlur={validateOnBlur}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                onChange={(e) =>
                  this.setState((prev) => {
                    return {
                      ...prev,
                      bookingData: {
                        ...prev.bookingData,
                        phone_number: e.target.value,
                      },
                    };
                  })
                }
              />
              {errorMessages.find((item) => item.name === "phone_number") && (
                <p className="error-text">
                  {
                    errorMessages.find((item) => item.name === "phone_number")
                      .message
                  }
                </p>
              )}
            </div>
          </div>
          <div className="mb-3 booking-form__row">
            <div className="booking-form__label">
              <i className="far fa-envelope me-2" />
              <label className="form-label user-select-none">
                メールアドレス
              </label>
            </div>
            <div className="booking-form__control">
              <input
                className="form-control"
                name="email"
                placeholder="メールアドレス"
                maxLength="64"
                value={this.state.bookingData.email}
                onBlur={validateOnBlur}
                onChange={(e) =>
                  this.setState((prev) => {
                    return {
                      ...prev,
                      bookingData: {
                        ...prev.bookingData,
                        email: e.target.value,
                      },
                    };
                  })
                }
              />
              {errorMessages.find((item) => item.name === "email") && (
                <p className="error-text">
                  {errorMessages.find((item) => item.name === "email").message}
                </p>
              )}
            </div>
          </div>
          <div className="mb-3 booking-form__row required">
            <div className="booking-form__label">
              <i className="fab fa-elementor me-2" />
              <label className="form-label user-select-none">メニュー</label>
            </div>
            <div className="booking-form__control">
              <div className="menu-container custom-style-scrollbar">
                <ul className="menu-group">
                  {this.props.data.listMenu.map((item) => {
                    return (
                      <li className="inputGroup" key={item.id}>
                        <input
                          type="checkbox"
                          id={item.id}
                          value={item.id}
                          checked={bookingData.menu.includes(
                            item.id.toString()
                          )}
                          onChange={this.onCheckboxSelect}
                        />
                        <label htmlFor={item.id}>{item.name}</label>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {errorMessages.find((item) => item.name === "menu") && (
                <p className="error-text">
                  {errorMessages.find((item) => item.name === "menu").message}
                </p>
              )}
            </div>
          </div>
          <div className="mb-3 booking-form__row required">
            <div className="booking-form__label booking-form__label--create-booking-staff">
              <i className="fas fa-male me-2"></i>
              <label className="form-label user-select-none">
                {window.store.getState().auth.user.section_name_1}
              </label>
            </div>
            <div className="booking-form__control">
              <div>
                <select
                  className="form-select form-select-md outline-none"
                  aria-label=".form-select-md example"
                  name="staff"
                  onBlur={validateOnBlur}
                  value={this.state.bookingData.staff}
                  onChange={onSelectStaff}
                >
                  <option value="">選択してください</option>
                  {listFilteredStaff.map((item) => {
                    return (
                      <option value={item.id} title={item.name} key={item.id}>
                        {window._.truncate(item.name, {
                          length: 40,
                          omission: "...",
                        })}
                      </option>
                    );
                  })}
                </select>
              </div>
              {errorMessages.find((item) => item.name === "staff") && (
                <p className="error-text">
                  {errorMessages.find((item) => item.name === "staff").message}
                </p>
              )}
              <p className="booking-dialog__note">
                ※選択した
                {window.store.getState().auth.user.section_name_1}
                によってメニューが絞り込まれます。
              </p>
            </div>
          </div>
          <div className="mb-3 booking-form__row required">
            <div className="booking-form__label">
              <i className="fas fa-check-circle me-2"></i>
              <label className="form-label user-select-none">ステータス</label>
            </div>
            <div className="booking-form__control">
              <div>
                <select
                  className="form-select form-select-md outline-none"
                  aria-label=".form-select-md example"
                  disabled
                  value={this.state.bookingData.status}
                  onChange={(e) =>
                    this.setState((prev) => {
                      return {
                        ...prev,
                        bookingData: {
                          ...prev.bookingData,
                          status: e.target.value,
                        },
                      };
                    })
                  }
                >
                  {[
                    {
                      value: "waiting",
                      label: "予約待ち",
                    },
                    {
                      value: "approved",
                      label: "予約済み",
                    },
                    {
                      value: "processing",
                      label: "進行中",
                    },
                    {
                      value: "done",
                      label: "完了",
                    },
                    {
                      value: "cancelled",
                      label: "予約キャンセル",
                    },
                  ].map((item) => {
                    return (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="mb-3 booking-form__row required">
            <div className="booking-form__label">
              <i className="far fa-clock me-2" />
              <label
                htmlFor="customerTime"
                className="form-label user-select-none"
              >
                時間
              </label>
            </div>
            <div className="booking-form__control">
              <div className="date-time-wrapper">
                {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
                {/* <DateTimePicker
                    value={moment(this.state.bookingData.start_time).toDate()}
                    onChange={this.handleStartTimeChange}
                    okLabel={"完了"}
                    cancelLabel={"キャンセル"}
                  /> */}
                <div>
                  <span className="me-2">
                    {moment(this.state.bookingData.start_time).format(
                      "YYYY年 MM月 DD日"
                    )}
                  </span>
                  <select
                    value={moment(this.state.bookingData.start_time).format(
                      "HH:mm"
                    )}
                    onChange={changeStartTime}
                  >
                    {this.props.blockTimes.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                <span>~</span>

                <span>
                  {moment(this.state.bookingData.end_time).format(
                    "YYYY年 MM月 DD日 HH:mm"
                  )}
                </span>

                {/* <DateTimePicker
                    value={moment(this.state.bookingData.end_time).toDate()}
                    shouldDisableDate={this.disabledEndTime}
                    onChange={this.handleEndTimeChange}
                    okLabel={"完了"}
                    cancelLabel={"キャンセル"}
                  /> */}
                {/* </MuiPickersUtilsProvider> */}
              </div>
            </div>
          </div>
          <div className="mb-3 booking-form__row">
            <div className="booking-form__label">
              <i className="fas fa-feather me-2"></i>
              <label
                htmlFor="customerNote"
                className="form-label user-select-none"
              >
                注意
              </label>
            </div>
            <div className="booking-form__control">
              <textarea
                className="form-control resize-none outline-none"
                id="customerNote"
                rows="3"
                maxLength="500"
                onChange={(e) =>
                  this.setState((prev) => {
                    return {
                      ...prev,
                      bookingData: {
                        ...prev.bookingData,
                        note: e.target.value,
                      },
                    };
                  })
                }
              />
            </div>
          </div>
          <div className="booking-form__btns">
            <MyButton
              myVariant="create"
              // disabled={this.state.isBtnDisabled}
              isLoading={this.state.isBtnDisabled}
              onClick={this.createBooking}
            >
              作成
            </MyButton>

            <MyButton
              myVariant="cancel"
              disabled={this.state.isBtnDisabled}
              onClick={() => onClose({})}
            >
              キャンセル
            </MyButton>
          </div>
        </Dialog>
      )
    );
  }
}

export default CreateDialog;
