import React from "react";
import "./index.scss";
import moment from "moment";
import Dialog from "../../shared/dialog";
import "moment/locale/ja";
import MyButton from "./../../shared/buttons/primary";

moment.locale("ja");

class DetailDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bookingData: {},
    };
  }

  componentDidMount() {
    const bookingData = {
      id: this.props.data.id,
      username: this.props.data.username,
      phone_number: this.props.data.phone_number,
      note: this.props.data.note,
      shop: this.props.data.shop,
      menu: this.props.data.menu,
      status: this.props.data.status,
      start_time: moment(this.props.data.start_time, moment.ISO_8601).format(),
      end_time: moment(this.props.data.end_time, moment.ISO_8601).format(),
      staff: this.props.data.staff?.name || "",
      cancel_time: this.props.data.cancel_time,
      user: this.props.data.user,
    };
    this.setState((prevState) => {
      return {
        ...prevState,
        bookingData: bookingData,
      };
    });
  }

  render() {
    const { isShow, onClose } = this.props;
    const { bookingData } = this.state;
    return (
      isShow && (
        <Dialog
          id="history-detail-dialog"
          title={"詳細"}
          isShow={isShow}
          onCloseRequest={() => onClose({})}
          closeOnOverlayClick={true}
        >
          {!window.utils.isEmptyObject(bookingData) && (
            <div className="history-detail-wrapper">
              <ul className="history-detail">
                <li className="history-detail__item item">
                  <div className="item__ttl">
                    <h2>ユーザ名</h2>
                  </div>
                  <div className="item__dt">
                    <p>{bookingData.username || bookingData.user.full_name}</p>
                  </div>
                </li>
                <li className="history-detail__item item">
                  <div className="item__ttl">
                    <h2>電話番号</h2>
                  </div>
                  <div className="item__dt">
                    <p>
                      {bookingData.phone_number ||
                        bookingData.user.phone_number}
                    </p>
                  </div>
                </li>
                <li className="history-detail__item item">
                  <div className="item__ttl">
                    <h2>ステータス</h2>
                  </div>
                  <div className="item__dt">
                    <p>
                      {
                        [
                          {
                            value: "waiting",
                            label: "予約待ち",
                          },
                          {
                            value: "approved",
                            label: "予約済み",
                          },
                          {
                            value: "processing",
                            label: "進行中",
                          },
                          {
                            value: "done",
                            label: "完了",
                          },
                          {
                            value: "cancelled",
                            label: "予約キャンセル",
                          },
                        ].find((status) => status.value === bookingData.status)
                          .label
                      }
                    </p>
                  </div>
                </li>
                <li className="history-detail__item item">
                  <div className="item__ttl">
                    <h2>メニュー</h2>
                  </div>
                  <div className="item__dt">
                    <ul>
                      {bookingData.menu
                        ? bookingData.menu.map((item) => {
                            return <li key={item.id}>{item.name}</li>;
                          })
                        : ""}
                    </ul>
                  </div>
                </li>
                <li className="history-detail__item item">
                  <div className="item__ttl">
                    <h2>{window.store.getState().auth.user.section_name_1}</h2>
                  </div>
                  <div className="item__dt">
                    <p>{bookingData.staff}</p>
                  </div>
                </li>
                <li className="history-detail__item item">
                  <div className="item__ttl">
                    <h2>時間</h2>
                  </div>
                  <div className="item__dt">
                    <span>
                      {moment(bookingData.start_time).format(
                        "YYYY年 MM月 DD日 hh:mm a"
                      )}
                    </span>
                    <span> ~ </span>
                    <span>
                      {moment(bookingData.end_time).format(
                        "YYYY年 MM月 DD日 hh:mm a"
                      )}
                    </span>
                  </div>
                </li>
                <li className="history-detail__item item">
                  <div className="item__ttl">
                    <h2>キャンセル時間</h2>
                  </div>
                  <div className="item__dt">
                    <span>
                      {bookingData.cancel_time
                        ? moment(bookingData.cancel_time).format(
                            "YYYY年 MM月 DD日 hh:mm a"
                          )
                        : ""}
                    </span>
                  </div>
                </li>
                <li className="history-detail__item item item__note">
                  <div className="item__ttl">
                    <h2>注意</h2>
                  </div>
                  <div className="item__dt">
                    <p>{bookingData.note}</p>
                  </div>
                </li>
              </ul>
            </div>
          )}
          <div className="d-flex justify-content-center">
            <MyButton myVariant="cancel" onClick={() => onClose({})}>
              キャンセル
            </MyButton>
          </div>
        </Dialog>
      )
    );
  }
}

export default DetailDialog;
