// import store from "./store";
// import { toast } from "react-toastify";
import axios from "axios";
import store from "./store";
import * as authTypes from "./../modules/auth/types";
import * as userTypes from "./../modules/user/types";

const { REACT_APP_BASE_API_URL } = process.env;

const service = axios.create({
  baseURL: REACT_APP_BASE_API_URL,
  timeout: 0,
  headers: {
    "Content-Type": "application/json",
  },
});

service.interceptors.request.use(
  async (config) => {
    const isAduser = !!window.store.getState().auth.token;
    if (isAduser) {
      config.headers["Authorization"] =
        "Bearer " + window.store.getState().auth.token;
    } else {
      const userToken = store.getState().user.userToken;
      if (userToken) {
        config.headers["Authorization"] = "Bearer " + userToken;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refresh = (refreshToken) => {
  return service({
    url: `token/refresh/`,
    method: "post",
    data: {
      refresh: refreshToken,
    },
  });
};

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

service.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 400) {
      // toast.error(
      //   `${Object.keys(error.response.data)[0]}: ${
      //     error.response.data[Object.keys(error.response.data)[0]]
      //   }`
      // );
    } else {
      // toast.error(error.response.data.detail);
    }

    if (error.response.status === 500) {
    } else if (error.response.status === 403) {
    } else if (error.response.status === 401) {
      if (!error.response.data.code) return;

      const originalRequest = error.config;
      const { refreshToken, token } = window.store.getState().auth;
      const { userRefreshToken } = window.store.getState().user;

      const isAduser = !!token;

      const rToken = isAduser ? refreshToken : userRefreshToken;

      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        refresh(rToken).then((result) => {
          if (isAduser) {
            window.store.dispatch({
              type: authTypes["SET_REFRESH_TOKEN"],
              payload: result.data.refresh,
            });
            window.store.dispatch({
              type: authTypes["SET_TOKEN"],
              payload: result.data.access,
            });
          } else {
            window.store.dispatch({
              type: userTypes["SET_USER_REFRESH_TOKEN"],
              payload: result.data.refresh,
            });
            window.store.dispatch({
              type: userTypes["SET_USER_TOKEN"],
              payload: result.data.access,
            });
          }

          isAlreadyFetchingAccessToken = false;
          onAccessTokenFetched(result.data.access);
        });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((access_token) => {
          originalRequest.headers.Authorization = "Bearer " + access_token;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }

    return Promise.reject(error);
  }
);

export default service;
