import * as types from "./types";

export const setSimpleCalendarChosenDate = (payload) => ({
  type: types["SET_SIMPLE_CALENDAR_CHOSEN_DATE"],
  payload,
});

export const setIsMobile = (payload) => ({
  type: types["SET_IS_MOBILE"],
  payload,
});

export const setWarningShowState = (payload) => ({
  type: types["SET_WARNING_SHOW_STATE"],
  payload,
});
