import sagas from "./../../modules/user/sagas";
import reducer from "./../../modules/user/reducer";
import constant from "./../../core/constant";

// const History = lazy(() => import("./../../controllers/history"));
import UserBookingHome from "./../../controllers/user/homes";
import UserBookingStaff from "./../../controllers/user/staffs";
import UserBookingMenu from "./../../controllers/user/menus";
import UserBookingTime from "./../../controllers/user/time";
import UserBookingHistory from "../../controllers/user/history";
import UserBookingSuccess from "./../../controllers/user/booking-success";
import UserLogin from "./../../controllers/user/login";
import UserRegister from "./../../controllers/user/register";
import TermOfService from "./../../controllers/user/terms-of-service";
import UserRedirect from "./../../controllers/user/redirect";

const user = [
  {
    name: "user",
    path: "/user-booking/redirect",
    component: UserRedirect,
    exact: true,
    type: constant.ROUTE_TYPE_USER,
  },
  {
    name: "user",
    path: "/:id/booking",
    component: UserBookingHome,
    // protected: true,
    exact: true,
    type: constant.ROUTE_TYPE_USER,
    reducer,
    sagas,
  },
  {
    name: "user",
    path: "/:id/booking/staffs",
    component: UserBookingStaff,
    // protected: true,
    type: constant.ROUTE_TYPE_USER,
    exact: true,
  },
  {
    name: "user",
    path: "/:id/booking/menus",
    component: UserBookingMenu,
    // protected: true,
    type: constant.ROUTE_TYPE_USER,
    exact: true,
  },
  {
    name: "user",
    path: "/:id/booking/time",
    component: UserBookingTime,
    // protected: true,
    type: constant.ROUTE_TYPE_USER,
    exact: true,
  },
  {
    name: "user",
    path: "/:id/booking/history",
    component: UserBookingHistory,
    // protected: true,
    type: constant.ROUTE_TYPE_USER,
    exact: true,
  },
  {
    name: "user",
    path: "/:id/booking/:bookingId/booking-success",
    component: UserBookingSuccess,
    // protected: true,
    type: constant.ROUTE_TYPE_USER,
    exact: true,
  },
  {
    name: "user",
    path: "/booking/user/login",
    component: UserLogin,
    // protected: true,
    type: constant.ROUTE_TYPE_USER,
    exact: true,
  },
  {
    name: "user",
    path: "/:id/booking/user/register",
    component: UserRegister,
    type: constant.ROUTE_TYPE_USER,
    exact: true,
  },
  {
    name: "user",
    path: "/booking/term-of-service",
    component: TermOfService,
    type: constant.ROUTE_TYPE_USER,
    exact: true,
  },
];

export default user;
