import * as types from "./types";

export const getListMenu = (payload) => ({
  type: types["GET_LIST_MENU"],
  payload,
});

export const setListMenu = (payload) => ({
  type: types["SET_LIST_MENU"],
  payload,
});

export const getListStaff = (payload) => ({
  type: types["GET_LIST_STAFF"],
  payload,
});

export const setListStaff = (payload) => ({
  type: types["SET_LIST_STAFF"],
  payload,
});
