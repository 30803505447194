import React from "react";
import Dialog from "../../../../shared/dialog";
import {
  schemaNoUrl,
  schemaWithUrl,
  schemaValidateLinkYoutube,
} from "../validate";
import "./index.scss";
import MyButton from "./../../../../shared/buttons/primary";
import request from "./../../../../../core/api";
import cloneDeep from "lodash/cloneDeep";

class MenuDialog extends React.Component {
  constructor(props) {
    super(props);

    this.utils = window.utils;

    this.state = {
      isCheckAll: false,
      isBtnDisabled: false,
      imgBlob: "",
      linkYoutube: "",
      imageTypeError: "",
      errorMessages: [],
      youtubeErrorType: 0,
      staffData: {
        active: true,
        detail: "",
        image: "",
        media_type: "image",
        name: "",
        shop: window.store.getState().auth.user.id,
        menus: [],
      },
      errorValidateSchema: {
        name: false,
        detail: false,
        image: false,
      },
      masterWorkingHoursStart: props.masterWorkingHoursStart,
      masterWorkingHoursEnd: props.masterWorkingHoursEnd,
      chosenWorkingHours: {},
      workingHourCheckboxes: {},
      masterOptions: {},
      workingHourError: {},
    };

    const { masterWorkingHoursStart, masterWorkingHoursEnd } = this.state;
    const defaultChosenWorkingHour = {};
    const workingHourCheckboxes = {};
    const masterOptions = {};
    const workingHourError = {};

    for (let item in masterWorkingHoursStart) {
      masterWorkingHoursStart[item].forEach((wHour, index) => {
        const valueStart = wHour;
        const valueEnd = masterWorkingHoursEnd[item][index];

        if (!defaultChosenWorkingHour[item]) {
          defaultChosenWorkingHour[item] = [];
          masterOptions[item] = [];
        }

        defaultChosenWorkingHour[item].push({
          start: wHour[0],
          end: valueEnd[valueEnd.length - 1],
          rangeStart: valueStart,
          rangeEnd: valueEnd,
        });

        masterOptions[item].push({
          start: wHour[0],
          end: valueEnd[valueEnd.length - 1],
          rangeStart: valueStart,
          rangeEnd: valueEnd,
        });
      });

      workingHourError[item] = "";
      workingHourCheckboxes[item] = true;
    }

    this.state.chosenWorkingHours = defaultChosenWorkingHour;
    this.state.workingHourError = workingHourError;
    this.state.workingHourCheckboxes = workingHourCheckboxes;

    const masterOptionsMap = {};
    for (let item in masterOptions) {
      masterOptionsMap[item] = {};
      masterOptionsMap[item].show = false;
      masterOptionsMap[item].blocks = masterOptions[item];
    }

    this.state.masterOptions = masterOptionsMap;
  }

  async componentDidMount() {
    if (window.store.getState().booking.isMobile) {
      const StaffDialogNode = document.querySelector("#staff-dialog");
      StaffDialogNode.addEventListener("scroll", () => {
        document.activeElement.blur();
      });
    }
  }

  getAvailableHours(date) {
    return request({
      url: `shop/${window.store.getState().auth.user.id}/available/`,
      method: "get",
      params: {
        date,
      },
    });
  }

  validateOnBlur = async (e) => {
    let errorList;
    let errorMsgState = this.state.errorMessages;

    if (e.target.name === "image" && !e.target.value) {
      this.setState((prev) => ({
        ...prev,
        errorMessages: prev.errorMessages.filter(
          (item) => item.name !== e.target.name
        ),
      }));
      return;
    }
    const {
      error: { details },
    } =
      e.target.name !== "image"
        ? schemaNoUrl.validate({
            [e.target.name]: e.target.value,
          })
        : schemaWithUrl.validate({
            [e.target.name]: e.target.value,
          });
    errorList = details;

    const error = errorList.find(
      (item) => item.context.label === e.target.name
    );

    if (!error) {
      const errorIdx = errorMsgState.findIndex(
        (error) => error.name === e.target.name
      );
      if (errorIdx !== -1) {
        errorMsgState.splice(errorIdx, 1);
      }
    } else {
      const item = errorMsgState.find(
        (item) => item.name === error.context.label
      );

      if (!item) {
        errorMsgState.push({
          name: e.target.name,
          message: error.message,
        });
      } else {
        errorMsgState = errorMsgState.map((item) => {
          if (item.name === error.context.label) {
            return {
              name: e.target.name,
              message: error.message,
            };
          }

          return item;
        });
      }
    }

    this.setState((prev) => ({
      ...prev,
      errorMessages: errorMsgState,
    }));
  };

  onRadioChange = async (e) => {
    const { value } = e.target;
    await this.setState((prev) => ({
      ...prev,
      staffData: {
        ...prev.staffData,
        media_type: value,
        image:
          value === "youtube" ? this.state.linkYoutube : this.state.imgBlob,
      },
    }));

    this.setState((prev) => ({
      ...prev,
      errorValidateSchema: {
        ...prev.errorValidateSchema,
        image: this.state.staffData.image ? false : true,
      },
    }));
  };

  createStaff = async () => {
    await this.setState({ isBtnDisabled: true });

    const { staffData } = this.state;
    let errors;
    const validateLabels = ["name", "detail", "menus"];

    const validate =
      staffData.media_type === "image"
        ? schemaNoUrl.validate(staffData)
        : schemaWithUrl.validate(staffData);

    if (staffData.media_type === "youtube" && staffData.image) {
      validateLabels.push("image");
    }

    errors = validate.error.details.filter((item) =>
      validateLabels.includes(item.context.label)
    );

    errors = errors.map((item) => {
      return {
        name: item.context.label,
        message: item.message,
      };
    });

    if (errors.length) {
      this.setState((prev) => ({
        ...prev,
        errorMessages: errors,
      }));

      this.setState({ isBtnDisabled: false });
      return;
    }

    let workingHourValidate = true;
    for (let item in this.state.workingHourError) {
      if (
        this.state.workingHourError[item] &&
        this.state.workingHourCheckboxes[item]
      ) {
        workingHourValidate = false;
        break;
      }
    }

    if (!workingHourValidate) {
      this.setState({ isBtnDisabled: false });
      return;
    }

    const dataSubmit = staffData;
    if (
      dataSubmit.media_type === "image" &&
      typeof this.state.imgBlob === "object"
    ) {
      const link = (await window.utils.uploadFile(this.state.imgBlob)).data
        .link;
      dataSubmit.image = link;
    }

    let staffWorkingTime = cloneDeep(this.state.chosenWorkingHours);

    for (let item in staffWorkingTime) {
      for (let index = 0; index < staffWorkingTime[item].length; ++index) {
        delete staffWorkingTime[item][index].rangeEnd;
        delete staffWorkingTime[item][index].rangeStart;
      }
    }

    for (let item in this.state.workingHourCheckboxes) {
      if (!this.state.workingHourCheckboxes[item]) {
        delete staffWorkingTime[item];
      }
    }

    for (let key in staffWorkingTime) {
      staffWorkingTime[key] = staffWorkingTime[key].reduce((acc, curr) => {
        if (!acc.length) {
          acc.push(curr);
        } else {
          const existed = acc.find(
            (item) => item.start === curr.start && item.end === curr.end
          );
          if (!existed) {
            acc.push(curr);
          }
        }

        return acc;
      }, []);
    }

    dataSubmit.working_time = staffWorkingTime;

    window
      .axios({
        url: `staff/`,
        method: "post",
        data: dataSubmit,
      })
      .then((result) => {
        this.onCloseRequest(result.data);
      });
  };

  uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    return window.axios({
      url: "upload/",
      method: "put",
      header: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  };

  onCloseRequest = (data) => {
    this.props.onCloseRequest(data);
  };

  onSelectImage = async (e) => {
    const imgBlob = e.target.files[0];
    const { isValid, error } = window.utils.validateImage(imgBlob);

    this.setState((prev) => ({
      ...prev,
      imageTypeError: error,
    }));
    if (!isValid) {
      return;
    }
    imgBlob.path = URL.createObjectURL(e.target.files[0]);

    this.setState((prev) => ({
      ...prev,
      imgBlob,
      staffData: {
        ...prev.staffData,
        image: imgBlob.path,
      },
    }));
  };

  changeStaffStatus = (e) => {
    this.setState((prev) => ({
      ...prev,
      staffData: {
        ...prev.staffData,
        active: e.target.checked,
      },
    }));
  };

  removeImage = () => {
    this.setState((prev) => ({
      imgBlob: "",
      staffData: { ...prev.staffData, image: "" },
    }));
  };

  workingHourStartTimeChange = (chosen, index, e) => {
    const newValue = cloneDeep(this.state.chosenWorkingHours);
    newValue[chosen][index].start = e.target.value;
    this.setState({ chosenWorkingHours: newValue });
  };

  workingHourEndTimeChange = (chosen, index, e) => {
    const newValue = cloneDeep(this.state.chosenWorkingHours);
    newValue[chosen][index].end = e.target.value;
    this.setState({ chosenWorkingHours: newValue });
  };

  removeSelecte = (chosen, index) => {
    const newValue = cloneDeep(this.state.chosenWorkingHours);
    newValue[chosen].splice(index, 1);

    const newWorkingHourError = cloneDeep(this.state.workingHourError);
    if (!newValue[chosen].length) {
      newWorkingHourError[chosen] = "出勤時間を選択してください。";
    }

    this.setState({
      chosenWorkingHours: newValue,
      workingHourError: newWorkingHourError,
    });
  };

  onWorkingCheckboxChange = (chosen, e) => {
    const newValue = cloneDeep(this.state.workingHourCheckboxes);
    newValue[chosen] = e.target.checked;

    this.setState({ workingHourCheckboxes: newValue });
  };

  choseMoreBlock = (e, chosen, item) => {
    const newValue = cloneDeep(this.state.chosenWorkingHours);
    const newWorkingHourError = cloneDeep(this.state.workingHourError);
    newWorkingHourError[chosen] = "";

    newValue[chosen].push({
      start: item.start,
      end: item.end,
      rangeStart: item.rangeStart,
      rangeEnd: item.rangeEnd,
    });

    this.setState({
      chosenWorkingHours: newValue,
      workingHourError: newWorkingHourError,
    });
  };

  checkAllMenu = async () => {
    await this.setState((prev) => ({
      ...prev,
      isCheckAll: !prev.isCheckAll,
    }));

    if (this.state.isCheckAll) {
      let errMsg = this.state.errorMessages;
      errMsg = errMsg.filter((item) => item.name !== "menus");

      const allMenuIds = cloneDeep(
        window.store.getState().setting.listMenu.map((item) => item.id)
      );

      this.setState((prev) => ({
        ...prev,
        staffData: {
          ...prev.staffData,
          menus: allMenuIds,
        },
        errorMessages: errMsg,
      }));
    } else {
      let errMsg = this.state.errorMessages;
      errMsg.push({
        name: "menus",
        message: "メニューを選択してください。",
      });

      this.setState((prev) => ({
        ...prev,
        staffData: {
          ...prev.staffData,
          menus: [],
        },
        errorMessages: errMsg,
      }));
    }
  };

  onCheckboxSelect = (e) => {
    const { value } = e.target;
    let menus = cloneDeep(this.state.staffData.menus);

    if (menus.includes(+value)) {
      menus = menus.filter((item) => item !== +value);
    } else {
      menus.push(+value);
    }

    if (menus.length) {
      let errMsg = this.state.errorMessages;
      errMsg = errMsg.filter((item) => item.name !== "menus");

      this.setState((prev) => ({
        ...prev,
        errorMessages: errMsg,
      }));
    } else {
      let errMsg = this.state.errorMessages;

      errMsg.push({
        name: "menus",
        message: "メニューを選択してください。",
      });

      this.setState((prev) => ({
        ...prev,
        errorMessages: errMsg,
      }));
    }

    this.setState((prev) => ({
      ...prev,
      isCheckAll:
        menus.length === window.store.getState().setting.listMenu.length
          ? true
          : false,
      staffData: {
        ...prev.staffData,
        menus,
      },
    }));
  };

  render() {
    const {
      onCloseRequest,
      validateOnBlur,
      changeStaffStatus,
      onRadioChange,
      onSelectImage,
      createStaff,
      removeImage,
      workingHourStartTimeChange,
      workingHourEndTimeChange,
      onWorkingCheckboxChange,
      removeSelecte,
      choseMoreBlock,
      checkAllMenu,
      onCheckboxSelect,
    } = this;
    const {
      staffData,
      imageTypeError,
      linkYoutube,
      errorMessages,
      imgBlob,
      chosenWorkingHours,
      workingHourCheckboxes,
      masterOptions,
      workingHourError,
      isCheckAll,
    } = this.state;
    const { isShow } = this.props;

    return (
      <Dialog
        id="staff-dialog"
        className={"booking-dialog menu-dialog staff-dialog"}
        title={`${window.store.getState().auth.user.section_name_1}追加`}
        isShow={isShow}
        onCloseRequest={() => onCloseRequest({})}
        closeOnOverlayClick={false}
      >
        <div className="booking-form__row required">
          <div className="booking-form__label">
            <label className="form-label user-select-none">
              {window.store.getState().auth.user.section_name_1}名
            </label>
          </div>
          <div className="booking-form__control">
            <input
              className="form-control"
              placeholder={`${
                window.store.getState().auth.user.section_name_1
              }名`}
              value={staffData.name}
              name="name"
              autoFocus
              maxLength="1000"
              onBlur={validateOnBlur}
              onChange={(e) =>
                this.setState((prev) => ({
                  ...prev,
                  staffData: {
                    ...prev.staffData,
                    name: !e.target.value.trim() ? "" : e.target.value,
                  },
                }))
              }
            />
            {errorMessages.find((item) => item.name === "name") && (
              <p className="error-text">
                {errorMessages.find((item) => item.name === "name").message}
              </p>
            )}
          </div>
        </div>
        <div className="booking-form__row required">
          <div className="booking-form__label">
            <label
              htmlFor="customerNote"
              className="form-label user-select-none"
            >
              説明
            </label>
          </div>
          <div className="booking-form__control">
            <textarea
              className="form-control resize-none outline-none"
              rows="3"
              name="detail"
              placeholder="説明内容"
              value={staffData.detail}
              onBlur={validateOnBlur}
              onChange={(e) =>
                this.setState((prev) => ({
                  ...prev,
                  staffData: {
                    ...prev.staffData,
                    detail: !e.target.value.trim() ? "" : e.target.value,
                  },
                }))
              }
            />
            {errorMessages.find((item) => item.name === "detail") && (
              <p className="error-text">
                {errorMessages.find((item) => item.name === "detail").message}
              </p>
            )}
          </div>
        </div>
        <div className="booking-form__row">
          <div className="booking-form__label">
            <label className="form-label user-select-none">ステータス</label>
          </div>
          <div className="booking-form__control control-price">
            <div className="form-switch">
              <input
                checked={staffData.active}
                className="form-check-input"
                type="checkbox"
                onChange={changeStaffStatus}
              />
            </div>
          </div>
        </div>
        <div className="booking-form__row">
          <div className="booking-form__label">
            <label className="form-label user-select-none">出勤時間</label>
          </div>
          <div className="booking-form__control control-working-hour">
            {Object.keys(chosenWorkingHours).map((chosen) => (
              <div
                className="control-working-hour__select-wrapper"
                key={chosen}
              >
                <div>
                  <input
                    id={`${chosen}-cb`}
                    value={chosen}
                    type="checkbox"
                    checked={!!workingHourCheckboxes[chosen]}
                    onChange={(e) => onWorkingCheckboxChange(chosen, e)}
                  />
                  <label htmlFor={`${chosen}-cb`}>
                    {
                      [
                        "日曜日",
                        "月曜日",
                        "火曜日",
                        "水曜日",
                        "木曜日",
                        "金曜日",
                        "土曜日",
                      ][chosen]
                    }
                  </label>
                </div>
                {workingHourCheckboxes[chosen] ? (
                  <div className="control-working-hour__select">
                    {chosenWorkingHours[chosen].map((item, index) => (
                      <div key={index}>
                        <select
                          value={item.start}
                          onChange={(e) =>
                            workingHourStartTimeChange(chosen, index, e)
                          }
                        >
                          {item.rangeStart.map((i, idx) => (
                            <option
                              key={`${index + idx}`}
                              value={i}
                              disabled={i >= item.end}
                            >
                              {i}
                            </option>
                          ))}
                        </select>{" "}
                        ~{" "}
                        <select
                          value={item.end}
                          onChange={(e) =>
                            workingHourEndTimeChange(chosen, index, e)
                          }
                        >
                          {item.rangeEnd.map((i, idx) => (
                            <option
                              key={`${index + idx}`}
                              value={i}
                              disabled={i <= item.start}
                            >
                              {i}
                            </option>
                          ))}
                        </select>
                        {
                          <svg
                            className="control-working-hour__remove-btn"
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#000000"
                            onClick={() => removeSelecte(chosen, index)}
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                          </svg>
                        }
                      </div>
                    ))}

                    {workingHourCheckboxes[chosen] ? (
                      <div
                        style={{ position: "relative" }}
                        className="control-working-hour__block-selector-wrapper"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          enableBackground="new 0 0 24 24"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#000000"
                          tabIndex="0"
                        >
                          <g>
                            <rect fill="none" height="24" width="24" />
                          </g>
                          <g>
                            <g>
                              <polygon points="10,8 10,14 14.7,16.9 15.5,15.7 11.5,13.3 11.5,8" />
                              <path d="M17.92,12c0.05,0.33,0.08,0.66,0.08,1c0,3.9-3.1,7-7,7s-7-3.1-7-7c0-3.9,3.1-7,7-7c0.7,0,1.37,0.1,2,0.29V4.23 C12.36,4.08,11.69,4,11,4c-5,0-9,4-9,9s4,9,9,9s9-4,9-9c0-0.34-0.02-0.67-0.06-1H17.92z" />
                              <polygon points="20,5 20,2 18,2 18,5 15,5 15,7 18,7 18,10 20,10 20,7 23,7 23,5" />
                            </g>
                          </g>
                        </svg>
                        {/* {masterOptions[chosen].show ? ( */}
                        <ul className="control-working-hour__block-selector">
                          {masterOptions[chosen].blocks.map((item, index) => (
                            <li
                              key={index}
                              onClick={(e) => choseMoreBlock(e, chosen, item)}
                            >
                              {item.start} ~ {item.end}
                            </li>
                          ))}
                        </ul>
                        {/* ) : (
                          ""
                        )} */}
                      </div>
                    ) : (
                      ""
                    )}

                    {workingHourError[chosen] ? (
                      <p className="error-text" style={{ margin: "0" }}>
                        {workingHourError[chosen]}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div>休業日</div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="mb-3 booking-form__row required">
          <div className="booking-form__label">
            <label className="form-label user-select-none">メニュー設定</label>
          </div>
          <div className="booking-form__control">
            <div className="menu-container custom-style-scrollbar">
              <button
                className={[
                  "staff-dialog__check-all-button",
                  isCheckAll ? "staff-dialog__check-all-button--check-all" : "",
                ].join(" ")}
                onClick={checkAllMenu}
              >
                すべて
              </button>
              <ul className="menu-group">
                {window.store.getState().setting.listMenu.map((item) => {
                  return (
                    <li className="inputGroup" key={item.id}>
                      <input
                        type="checkbox"
                        id={item.id}
                        value={item.id}
                        onChange={onCheckboxSelect}
                        checked={staffData.menus.includes(item.id)}
                      />
                      <label htmlFor={item.id}>{item.name}</label>
                    </li>
                  );
                })}
              </ul>
            </div>
            {errorMessages.find((item) => item.name === "menus") && (
              <p className="error-text">
                {errorMessages.find((item) => item.name === "menus").message}
              </p>
            )}
          </div>
        </div>
        <div className="booking-form__row">
          <div className="booking-form__label">
            <label className="form-label user-select-none">
              写真・YOUTUBEリンク
            </label>
          </div>
          <div className="booking-form__control form-image">
            <div className="mb-3">
              <input
                type="radio"
                name="radio"
                id="image-radio"
                value={"image"}
                checked={staffData.media_type === "image"}
                onChange={onRadioChange}
                className="me-2"
              />
              <label htmlFor="image-radio" className="form-check-label me-5">
                写真
              </label>

              <input
                type="radio"
                name="radio"
                id="link-yt-radio"
                value={"youtube"}
                checked={staffData.media_type === "youtube"}
                onChange={onRadioChange}
                className="me-2"
              />
              <label htmlFor="link-yt-radio" className="form-check-label">
                YOUTUBE リンク
              </label>
            </div>

            <div>
              {staffData.media_type === "image" ? (
                <>
                  <div
                    className="edit-button"
                    onClick={() => {
                      document.getElementById("create-staff-upload").click();
                    }}
                  >
                    画像をアップロードする
                  </div>
                  <input
                    type="file"
                    id="create-staff-upload"
                    onClick={(e) => {
                      e.target.value = "";
                    }}
                    hidden
                    accept="image/*"
                    onChange={onSelectImage}
                  />
                </>
              ) : (
                <>
                  <input
                    className="form-control price-input"
                    value={linkYoutube}
                    onChange={(e) => {
                      const { error } = schemaValidateLinkYoutube.validate({
                        image: e.target.value,
                      });

                      if (error) {
                        const errorMsg = this.state.errorMessages;
                        const index = errorMsg.findIndex(
                          (item) => item.name === e.target.name
                        );
                        if (index !== -1) {
                          errorMsg.splice(index, 1);
                        }

                        this.setState((prev) => ({
                          ...prev,
                          errorMessages: [
                            ...errorMsg,
                            {
                              message: error.details[0].message,
                              name: "image",
                            },
                          ],
                        }));
                      }
                      this.setState((prev) => ({
                        ...prev,
                        linkYoutube: e.target.value,
                        staffData: {
                          ...prev.staffData,
                          image: e.target.value,
                        },
                      }));
                    }}
                    name="image"
                    placeholder="Youtubeリンク"
                    onBlur={validateOnBlur}
                  />
                  {staffData.media_type === "youtube" &&
                  linkYoutube &&
                  !errorMessages.find((item) => item.name === "image") ? (
                    <figure
                      style={{
                        width: "200px",
                        height: "200px",
                        marginTop: "1rem",
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={`https://img.youtube.com/vi/${
                          linkYoutube.match(
                            //eslint-disable-next-line
                            /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
                          )[1]
                        }/0.jpg`}
                        alt="thumbnail"
                      />
                    </figure>
                  ) : (
                    ""
                  )}
                  {errorMessages.find((item) => item.name === "image") && (
                    <p className="error-text">
                      {
                        errorMessages.find((item) => item.name === "image")
                          .message
                      }
                    </p>
                  )}
                </>
              )}
              {staffData.media_type === "image" && imgBlob.path ? (
                <figure className="image-preview">
                  <span className="material-icons" onClick={removeImage}>
                    highlight_off
                  </span>
                  <img src={imgBlob.path} alt={"cippo"}></img>
                </figure>
              ) : (
                ""
              )}
              {imageTypeError ? (
                <p className="error-text auto-hide">{imageTypeError}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="booking-form__btns">
          <MyButton
            myVariant="cancel"
            disabled={this.state.isBtnDisabled}
            onClick={() => onCloseRequest({})}
          >
            キャンセル
          </MyButton>
          <MyButton
            myVariant="create"
            isLoading={this.state.isBtnDisabled}
            onClick={createStaff}
          >
            確定
          </MyButton>
        </div>
      </Dialog>
    );
  }
}

export default MenuDialog;
