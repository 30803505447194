// import { call, put, all, takeLatest } from "redux-saga/effects";
// import { setListMenu } from "./actions";
// import { getListMenu } from "./api";
// import { GET_LIST_MENU } from "./types";

// function* handleGetListMenu(action) {
//   try {
//     const result = yield call(getListMenu, action.payload);
//     const menus = result.data;
//     yield put(setListMenu(menus));
//   } catch (e) {
//     // yield put(actions.loginError());
//     throw e;
//   }
// }

function* appSaga() {
  // yield all([takeLatest(GET_LIST_MENU, handleGetListMenu)]);
}

export default appSaga;
