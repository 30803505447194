import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/js/bootstrap.min.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./styles/index.scss";
import axios from "./core/api";
import _ from "lodash";
import store from "./core/store";
import utils from "./core/utils";

Object.defineProperty(window, "utils", {
  value: utils,
});
Object.defineProperty(window, "axios", {
  value: axios,
});
Object.defineProperty(window, "_", {
  value: _,
});

Object.defineProperty(window, "store", {
  value: store,
});

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
