import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "./core/store";
import Router from "./routers";
import BaseLayout from "./controllers/base";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

class App extends Component {
  render() {
    return (
      <>
        <Provider store={store}>
          <BrowserRouter>
            <BaseLayout>
              <Router />
            </BaseLayout>
          </BrowserRouter>
        </Provider>
        <ToastContainer />
      </>
    );
  }
}

export default App;
