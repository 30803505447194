import moment from "moment";
import React, { Component } from "react";
import Dialog from "../../../../shared/dialog";
import {
  schemaNoUrl,
  schemaWithUrl,
  schemaValidateLinkYoutube,
} from "../validate";
import { timeBlockMinute, timeBlockHour } from "../constant";
import MyButton from "./../../../../shared/buttons/primary";

//eslint-disable-next-line
Number.prototype.format = function (n, x, s, c) {
  //eslint-disable-next-line
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
    num = this.toFixed(Math.max(0, ~~n));

  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    "$&" + (s || ",")
  );
};

class EditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeBlock: timeBlockMinute,
      imgBlob: "",
      imgPath: "",
      linkYoutube: "",
      timeUnit: 1,
      listDiscount: [],
      listDiscountImutate: [],
      imageTypeError: "",
      errorMessages: [],
      menuData: {
        name: "",
        price: "",
        price_text: "",
        image: "",
        media_type: "",
        detail: "",
        duration: "",
        discount: null,
        shop: window.store.getState().auth.user.id,
      },
    };
  }

  async componentDidMount() {
    await this.fetchListDiscount();

    const data = window._.cloneDeep(this.props.data);
    data.price = data.price.toString().split(".")[0];

    const newListDiscount = this.state.listDiscountImutate.filter((item) => {
      if (item.type === "Percentage") {
        return item;
      }

      if (item.type === "Fixed") {
        if (data.price >= Math.trunc(item.value)) {
          return item;
        }
      }
      return false;
    });

    this.setState((prev) => ({
      ...prev,
      imgPath:
        this.props.data.media_type === "image" ? this.props.data.image : "",
      linkYoutube:
        this.props.data.media_type === "youtube" ? this.props.data.image : "",
      listDiscount: newListDiscount,
      menuData: {
        ...prev.menuData,
        ...data,
      },
    }));
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const snapshot = {};
    if (this.state.menuData.price !== prevState.menuData.price) {
      snapshot.priceChange = true;
    }
    return snapshot;
  }

  addScrollEvent = (node) => {
    this.addScrollEvent = function () {};

    node.addEventListener("scroll", () => {
      document.activeElement.blur();
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const menuDialogNode = document.querySelector("#edit-menu-dialog");
    if (window.store.getState().booking.isMobile && menuDialogNode) {
      this.addScrollEvent(menuDialogNode);
    }

    if (snapshot.priceChange) {
      const newListDiscount = this.state.listDiscountImutate.filter((item) => {
        if (item.type === "Percentage") {
          return item;
        }

        if (item.type === "Fixed") {
          if (this.state.menuData.price >= Math.trunc(item.value)) {
            return item;
          }
        }
        return false;
      });

      this.setState((prev) => ({
        ...prev,
        listDiscount: newListDiscount,
        menuData: {
          ...prev.menuData,
          discount:
            newListDiscount.find(
              (item) => item.id === +this.state.menuData.discount
            )?.id || null,
        },
      }));
    }
  }

  fetchListDiscount = () => {
    return window
      .axios({
        url: `shop/${window.store.getState().auth.user.id}/discount/`,
        method: "get",
      })
      .then((result) => {
        const listDiscountAvailable = result.data.filter((item) => {
          if (moment().isBefore(item.valid_to)) {
            return item;
          }
          return false;
        });
        this.setState((prev) => ({
          ...prev,
          listDiscount: listDiscountAvailable,
          listDiscountImutate: listDiscountAvailable,
        }));
      });
  };

  onChangeRadio = (e) => {
    this.setState((prev) => ({
      ...prev,
      menuData: {
        ...prev.menuData,
        media_type: e.target.value,
        image:
          e.target.value === "image"
            ? this.state.imgPath
            : this.state.linkYoutube,
      },
    }));
  };

  onUnitChange = (e) => {
    this.setState((prev) => ({
      ...prev,
      timeBlock: e.target.value === "1" ? timeBlockMinute : timeBlockHour,
      timeUnit: e.target.value,
      menuData: {
        ...prev.menuData,
        duration: e.target.value === "1" ? "30" : "0.5",
      },
    }));
  };

  editMenu = async () => {
    await this.setState({ isBtnDisabled: true });

    const { menuData } = this.state;
    let errors;
    const validateLabels = ["name", "price", "detail"];

    const validate =
      menuData.media_type === "image"
        ? schemaNoUrl.validate(menuData)
        : schemaWithUrl.validate(menuData);

    if (menuData.media_type === "youtube" && menuData.image) {
      validateLabels.push("image");
    }

    errors = validate.error.details.filter((item) =>
      validateLabels.includes(item.context.label)
    );

    errors = errors.map((item) => {
      return {
        name: item.context.label,
        message: item.message,
      };
    });

    if (errors.length) {
      this.setState((prev) => ({
        ...prev,
        errorMessages: errors,
      }));

      this.setState({ isBtnDisabled: false });
      return;
    }

    const dataSubmit = menuData;
    if (
      dataSubmit.media_type === "image" &&
      typeof this.state.imgBlob === "object"
    ) {
      const link = (await window.utils.uploadFile(this.state.imgBlob)).data
        .link;
      dataSubmit.image = link;
    }

    dataSubmit.duration = dataSubmit.duration * this.state.timeUnit;

    if (dataSubmit.discount === "null") {
      dataSubmit.discount = JSON.parse(dataSubmit.discount);
    }

    window
      .axios({
        url: `menu/${dataSubmit.id}/`,
        method: "put",
        data: dataSubmit,
      })
      .then((result) => {
        this.onCloseRequest(result.data);
      });
  };

  onSelectImage = (e) => {
    const imgBlob = e.target.files[0];
    const { isValid, error } = window.utils.validateImage(imgBlob);

    this.setState((prev) => ({
      ...prev,
      imageTypeError: error,
    }));
    if (!isValid) {
      return;
    }
    // imgBlob.path = URL.createObjectURL(e.target.files[0]);

    this.setState((prev) => ({
      ...prev,
      imgBlob,
      imgPath: URL.createObjectURL(e.target.files[0]),
      menuData: {
        ...prev.menuData,
      },
    }));
  };

  validateOnBlur = (e) => {
    let errorList;
    let errorMsgState = this.state.errorMessages;

    if (e.target.name === "image" && !e.target.value) {
      this.setState((prev) => ({
        ...prev,
        errorMessages: prev.errorMessages.filter(
          (item) => item.name !== e.target.name
        ),
      }));
      return;
    }
    const {
      error: { details },
    } =
      e.target.name !== "image"
        ? schemaNoUrl.validate({
            [e.target.name]: e.target.value,
          })
        : schemaWithUrl.validate({
            [e.target.name]: e.target.value,
          });
    errorList = details;

    const error = errorList.find(
      (item) => item.context.label === e.target.name
    );

    if (!error) {
      const errorIdx = errorMsgState.findIndex(
        (error) => error.name === e.target.name
      );
      if (errorIdx !== -1) {
        errorMsgState.splice(errorIdx, 1);
      }
    } else {
      const item = errorMsgState.find(
        (item) => item.name === error.context.label
      );

      if (!item) {
        errorMsgState.push({
          name: e.target.name,
          message: error.message,
        });
      } else {
        errorMsgState = errorMsgState.map((item) => {
          if (item.name === error.context.label) {
            return {
              name: e.target.name,
              message: error.message,
            };
          }

          return item;
        });
      }
    }

    this.setState((prev) => ({
      ...prev,
      errorMessages: errorMsgState,
    }));
  };

  onCloseRequest = (data) => {
    this.props.onCloseRequest(data);
  };

  removeImage = () => {
    if (this.state.imgBlob) {
      this.setState({ imgBlob: "" });
    }

    this.setState((prev) => ({
      imgPath: "",
      menuData: {
        ...prev.menuData,
        image: "",
      },
    }));
  };

  render() {
    const {
      menuData,
      listDiscount,
      errorMessages,
      linkYoutube,
      imageTypeError,
      imgPath,
    } = this.state;
    const {
      onCloseRequest,
      validateOnBlur,
      onUnitChange,
      onChangeRadio,
      onSelectImage,
      editMenu,
      removeImage,
    } = this;
    return (
      <Dialog
        id="edit-menu-dialog"
        className={"booking-dialog menu-dialog"}
        title={"メニュー編集"}
        isShow={this.props.isShow}
        onCloseRequest={() => onCloseRequest({})}
        closeOnOverlayClick={false}
      >
        <div className="booking-form__row required">
          <div className="booking-form__label">
            <label className="form-label user-select-none">メニュー名</label>
          </div>
          <div className="booking-form__control">
            <textarea
              className="form-control"
              rows="2"
              placeholder="メニュー名"
              value={menuData.name}
              autoFocus
              name="name"
              maxLength="1000"
              onBlur={validateOnBlur}
              onChange={(e) => {
                this.setState((prev) => ({
                  ...prev,
                  menuData: {
                    ...prev.menuData,
                    name: !e.target.value.trim() ? "" : e.target.value,
                  },
                }));
              }}
            />
            {errorMessages.find((item) => item.name === "name") && (
              <p className="error-text">
                {errorMessages.find((item) => item.name === "name").message}
              </p>
            )}
          </div>
        </div>
        <div className="booking-form__row required">
          <div className="booking-form__label">
            <label
              htmlFor="customerNote"
              className="form-label user-select-none"
            >
              説明
            </label>
          </div>
          <div className="booking-form__control">
            <textarea
              className="form-control resize-none outline-none"
              rows="3"
              placeholder="説明内容"
              value={menuData.detail}
              name="detail"
              onBlur={validateOnBlur}
              onChange={(e) => {
                this.setState((prev) => ({
                  ...prev,
                  menuData: {
                    ...prev.menuData,
                    detail: !e.target.value.trim() ? "" : e.target.value,
                  },
                }));
              }}
            />
            {errorMessages.find((item) => item.name === "detail") && (
              <p className="error-text">
                {errorMessages.find((item) => item.name === "detail").message}
              </p>
            )}
          </div>
        </div>
        <div className="booking-form__row required">
          <div className="booking-form__label">
            <label className="form-label user-select-none">価格</label>
          </div>
          <div className="booking-form__control">
            <div className="control-price">
              <input
                className="form-control price-input"
                placeholder="円"
                value={menuData.price}
                name="price"
                maxLength="15"
                inputMode="decimal"
                onBlur={validateOnBlur}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                onChange={(e) => {
                  this.setState((prev) => ({
                    ...prev,
                    menuData: {
                      ...prev.menuData,
                      price: e.target.value,
                    },
                  }));
                }}
              />
              円
            </div>
            {menuData.price ? (
              <p style={{ fontSize: "15px", marginTop: "8px" }}>
                ￥{Number(menuData.price).format(0, 3, ",", ".")}
              </p>
            ) : (
              ""
            )}
            {errorMessages.find((item) => item.name === "price") && (
              <p className="error-text">
                {errorMessages.find((item) => item.name === "price").message}
              </p>
            )}
          </div>
        </div>
        <div className="booking-form__row required">
          <div className="booking-form__label">
            <label className="form-label user-select-none">時間</label>
          </div>
          <div className="booking-form__control">
            <div className="form-time">
              <select
                className="form-select form-select-md outline-none"
                value={menuData.duration}
                onChange={(e) => {
                  this.setState((prevState) => ({
                    ...prevState,
                    menuData: {
                      ...prevState.menuData,
                      duration: e.target.value,
                    },
                  }));
                }}
                aria-label=".form-select-md example"
              >
                {this.state.timeBlock.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </select>
              <select className="form-select" onChange={onUnitChange}>
                <option value="1">分</option>
                <option value="60">時間</option>
              </select>
            </div>
          </div>
        </div>
        <div className="booking-form__row">
          <div className="booking-form__label">
            <label className="form-label user-select-none">割引</label>
          </div>
          <div className="booking-form__control">
            <select
              className="form-select form-select-md outline-none select-discount"
              value={menuData.discount || ""}
              disabled={Math.trunc(menuData.price) < 1 || !listDiscount.length}
              onChange={(e) =>
                this.setState((prev) => ({
                  ...prev,
                  menuData: {
                    ...prev.menuData,
                    discount: e.target.value,
                  },
                }))
              }
            >
              <option defaultValue value="null">
                割引を選択してください
              </option>
              {listDiscount.map((item) => {
                return (
                  <option value={item.id} key={item.id}>
                    {window._.truncate(item.name, {
                      length: 30,
                      omission: "...",
                    })}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="booking-form__row">
          <div className="booking-form__label">
            <label className="form-label user-select-none">
              写真・YOUTUBEリンク
            </label>
          </div>
          <div className="booking-form__control form-image">
            <div className="mb-3 radio-wrapper">
              <input
                type="radio"
                name="radio"
                id="image-radio"
                value="image"
                checked={menuData.media_type === "image"}
                className="me-2"
                onChange={(e) => onChangeRadio(e)}
              />
              <label htmlFor="image-radio" className="form-check-label me-5">
                写真
              </label>

              <input
                type="radio"
                name="radio"
                value="youtube"
                id="youtube-radio"
                checked={menuData.media_type === "youtube"}
                className="me-2"
                onChange={(e) => onChangeRadio(e)}
              />
              <label htmlFor="youtube-radio" className="form-check-label">
                YOUTUBE リンク
              </label>
            </div>

            <div>
              {menuData.media_type === "image" ? (
                <div className="btn-wrapper">
                  <div
                    className="edit-button"
                    onClick={() => {
                      document.getElementById("edit-menu-upload").click();
                    }}
                  >
                    画像をアップロードする
                  </div>
                  <input
                    type="file"
                    id="edit-menu-upload"
                    hidden
                    accept="image/*"
                    onChange={onSelectImage}
                  />
                </div>
              ) : (
                <>
                  <input
                    className="form-control price-input"
                    value={linkYoutube}
                    onChange={(e) => {
                      const { error } = schemaValidateLinkYoutube.validate({
                        image: e.target.value,
                      });

                      if (error) {
                        const errorMsg = this.state.errorMessages;
                        const index = errorMsg.findIndex(
                          (item) => item.name === e.target.name
                        );
                        if (index !== -1) {
                          errorMsg.splice(index, 1);
                        }

                        this.setState((prev) => ({
                          ...prev,
                          errorMessages: [
                            ...errorMsg,
                            {
                              message: error.details[0].message,
                              name: "image",
                            },
                          ],
                        }));
                      }

                      this.setState((prev) => ({
                        ...prev,
                        linkYoutube: e.target.value,
                        menuData: {
                          ...prev.menuData,
                          image: e.target.value,
                        },
                      }));
                    }}
                    name="image"
                    placeholder="YOUTUBE リンク"
                    onBlur={validateOnBlur}
                  />
                  {menuData.media_type === "youtube" &&
                  linkYoutube &&
                  !errorMessages.find((item) => item.name === "image") ? (
                    <figure
                      style={{
                        width: "200px",
                        height: "200px",
                        marginTop: "1rem",
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={`https://img.youtube.com/vi/${
                          linkYoutube.match(
                            //eslint-disable-next-line
                            /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
                          )[1]
                        }/0.jpg`}
                        alt="thumbnail"
                      />
                    </figure>
                  ) : (
                    ""
                  )}
                  {errorMessages.find((item) => item.name === "image") && (
                    <p className="error-text">
                      {
                        errorMessages.find((item) => item.name === "image")
                          .message
                      }
                    </p>
                  )}
                </>
              )}
              {menuData.media_type === "image" && imgPath ? (
                <figure className="image-preview">
                  <span className="material-icons" onClick={removeImage}>
                    highlight_off
                  </span>
                  <img src={imgPath} alt={"cippo"}></img>
                </figure>
              ) : (
                ""
              )}
              {imageTypeError ? (
                <p className="error-text auto-hide">{imageTypeError}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="booking-form__btns">
          <MyButton
            className="me-3"
            myVariant="cancel"
            disabled={this.state.isBtnDisabled}
            onClick={() => onCloseRequest({})}
          >
            キャンセル
          </MyButton>
          <MyButton
            myVariant="create"
            isLoading={this.state.isBtnDisabled}
            onClick={editMenu}
          >
            確定
          </MyButton>
        </div>
      </Dialog>
    );
  }
}

export default EditDialog;
